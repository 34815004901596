import React from "react";
import { useSelector } from "react-redux";
import ReportMap from "@/features/reports/components/ReportMap";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportMapContainerProps {}

const ReportMapContainer: React.FC<ReportMapContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  return <ReportMap report={report} reportFetchState={reportFetchState} />;
};

export default ReportMapContainer;
