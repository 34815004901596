import React from "react";
import { SaleLimitedResponse } from "@doczip/api-client";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import GradientSlide from "@/components/slide/GradientSlide";
import SaleListItem from "@/features/sales/components/SaleListItem";
import { FetchState } from "@/interfaces/fetch";

type SaleGradientSlideProps = {
  list: SaleLimitedResponse[] | null;
  fetchState: FetchState;
  page?: number | null;
  totalPage?: number | null;
  onLoadMore?: () => void;
};

const SaleGradientSlider = ({ list, fetchState, page, totalPage, onLoadMore }: SaleGradientSlideProps) => {
  return (
    <GradientSlide>
      <InfiniteScrollList
        list={list}
        renderItem={(sale) => <SaleListItem key={sale.id} sale={sale} />}
        fetchState={fetchState}
        page={page || null}
        totalPage={totalPage || null}
        onLoadMore={onLoadMore}
      />
    </GradientSlide>
  );
};

export default SaleGradientSlider;
