import React from "react";
import styled from "styled-components";
import Link from "@/components/Link";
import { Button } from "@/components/Mui";
import { getUrl } from "@/utils/routes";

const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-right: 24px;
  padding-bottom: 72px;
  padding-left: 24px;

  color: #333;
  font-weight: 500;
  font-size: 13px;

  background-color: #fff;
`;

const NoResultRow = styled.div`
  text-align: center;
`;

interface NoResultProps {
  description: string | string[];
  children?: React.ReactNode;
}

export default function NoResult({ description, children }: NoResultProps) {
  return (
    <NoResultContainer>
      {typeof description === "string" ? (
        <NoResultRow>{description}</NoResultRow>
      ) : (
        description.map((descriptionRow, index) => <NoResultRow key={index}>{descriptionRow}</NoResultRow>)
      )}
      {children}
    </NoResultContainer>
  );
}

export function NoSearchResult() {
  return <NoResult description={["검색결과가 없습니다.", "다른 키워드로 재검색 해주세요"]} />;
}

export function NoReportLogResult() {
  return (
    <NoResultContainer>
      <NoResultRow>📂 아직 열람 내역이 없습니다.</NoResultRow>
      <NoResultRow>위 주소 검색을 통해 물건의 정보를 알아보세요!</NoResultRow>
      <Link href={getUrl("userTransactionsNew")} style={{ marginTop: "24px" }}>
        <Button className="outlined">다운로드권 충전</Button>
      </Link>
    </NoResultContainer>
  );
}

type NoResultChangeNotificationProps = {
  title: string;
  description: string;
};

export function NoResultChangeNotification({ title, description }: NoResultChangeNotificationProps) {
  return (
    <NoResultContainer>
      <NoResultRow>{title}</NoResultRow>
      <NoResultRow>{description}</NoResultRow>
    </NoResultContainer>
  );
}
