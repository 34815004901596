import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CenteredLayout from "@/components/CenteredLayout";
import Layout from "@/components/Layout";
import TextField, { TextFieldWithInnerButton } from "@/components/TextField";
import IamportLoader from "@/containers/IamportLoader";
import { FormContainer, StyledTextFieldInnerButton } from "@/features/user/components/resetPasswordLayout";
import { resetAuthState } from "@/redux/auth/actions";
import {
  getLoginFetchState,
  getResetPasswordFetchState,
  getSelfCertification,
  getSelfCertificationFetchState,
} from "@/redux/auth/selectors";
import { resetPasswordThunk, selfCertificationThunk } from "@/redux/auth/thunk";
import { getUrl } from "@/utils/routes";
import { passwordSchema, phoneNumberSchema } from "@/utils/validator";

interface ResetPasswordField {
  phoneNumber: string;
  password: string;
  impUid: string;
}

const resetPasswordSchema = yup.object<ResetPasswordField>({
  phoneNumber: phoneNumberSchema.required("현재 사용 가능한 휴대폰 번호를 입력해주세요."),
  password: passwordSchema.required("변경할 새 비밀번호를 입력해주세요."),
  impUid: yup.string().required("본인인증을 해 주세요"),
});

interface ResetPasswordBySelfCertificationPageProps {}

function ResetPasswordBySelfCertificationPage(props: ResetPasswordBySelfCertificationPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginFetchState = useSelector(getLoginFetchState);
  const resetPasswordFetchState = useSelector(getResetPasswordFetchState);
  const selfCertification = useSelector(getSelfCertification);
  const selfCertificationFetchState = useSelector(getSelfCertificationFetchState);

  useEffect(() => {
    dispatch(resetAuthState());
  }, []);

  useEffect(() => {
    if (loginFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("root"));
  }, [loginFetchState]);

  useEffect(() => {
    if (resetPasswordFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("login"));
  }, [resetPasswordFetchState]);

  const { handleSubmit, getFieldProps, errors, values, setFieldValue, validateField } = useFormik<ResetPasswordField>({
    validationSchema: resetPasswordSchema,
    initialValues: {
      phoneNumber: "",
      password: "",
      impUid: "",
    },
    onSubmit: (formField) => {
      dispatch(resetPasswordThunk(formField));
    },
  });

  useEffect(() => {
    setFieldValue("impUid", selfCertification ?? "");
  }, [selfCertification]);

  const handleSelfCertification = useCallback(() => {
    if (selfCertificationFetchState === "FETCHING") {
      return;
    }

    if (values.phoneNumber.length === 0) {
      validateField("phoneNumber");
      return;
    }

    dispatch(selfCertificationThunk(values.phoneNumber));
  }, [selfCertificationFetchState, values]);

  return (
    <>
      <CenteredLayout
        title="비밀번호 재설정"
        description={["닥집에 오신 것을 환영합니다.", "권리관계 확인으로 다양한 부동산 정보를 확인해보세요!"]}
      >
        <FormContainer onSubmit={handleSubmit}>
          <TextFieldWithInnerButton
            fullWidth
            placeholder="휴대폰 번호"
            {...getFieldProps("phoneNumber")}
            helperText={errors.phoneNumber}
            error={typeof errors.phoneNumber !== "undefined"}
            innerButton={
              <StyledTextFieldInnerButton
                disabled={!!errors.phoneNumber || !values.phoneNumber}
                onClick={handleSelfCertification}
              >
                본인인증
              </StyledTextFieldInnerButton>
            }
          />
          <TextField
            fullWidth
            sx={{ mb: "16px" }}
            placeholder="새 비밀번호"
            {...getFieldProps("password")}
            helperText={errors.password}
            error={typeof errors.password !== "undefined"}
            type="password"
          />
          <PrimaryButton fullWidth type="submit">
            비밀번호 재설정
          </PrimaryButton>
        </FormContainer>
      </CenteredLayout>
      <Layout columnless />
    </>
  );
}

export default function ResetPasswordBySelfCertificationPageWithIamportLoader(
  props: ResetPasswordBySelfCertificationPageProps,
) {
  return (
    <IamportLoader>
      <ResetPasswordBySelfCertificationPage {...props} />
    </IamportLoader>
  );
}
