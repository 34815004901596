import styled from "styled-components";
import { lengths } from "@/styles/values";

interface Props {
  px?: number;
  screenMaxWidth?: string;
}

const MobileInnerCommonLayout = styled.div<Props>`
  /* stylelint-disable */
  @media screen and (max-width: ${(props) => (props.screenMaxWidth ? props.screenMaxWidth : lengths.desktop)}) {
    padding-right: ${(props) => (props.px ? `${props.px}px` : `18px`)};
    padding-left: ${(props) => (props.px ? `${props.px}px` : `18px`)};
  }
`;

export default MobileInnerCommonLayout;
