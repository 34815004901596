import React, { useEffect } from "react";
import { DocumentListResponse, DocumentTypeEnum } from "@doczip/api-client";
import { CircularProgress, IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import styled from "styled-components";
import TrashCanImage from "@/assets/icons/trash-can.svg";
import LoadingCircle from "@/components/LoadingCircle";
import StyledButton from "@/components/StyledButton";
import SaleModalOpenButton from "@/features/sales/components/SaleModalOpenButton";
import { colors, lengths } from "@/styles/values";
import { toKoreanDate } from "@/utils/datetime";
import { confirm } from "@/utils/dialog";
import { useIntersect } from "@/utils/hooks";
import { getUrl } from "@/utils/routes";

const DocumentListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;

  background-color: ${colors.white};
  border-bottom: 1px solid #eeeff1;

  &:last-child {
    border-bottom: none;
  }
`;

const Title = styled.span`
  margin: 0;
  padding: 0;

  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 13px;
  }
`;

const Subtitle = styled.span`
  color: #718096;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 11px;
  }
`;

const TypeCell = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 12px;
  text-align: center;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 12px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;

  color: ${colors.dark};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  cursor: pointer;

  &:hover {
    ${Title} {
      text-decoration: underline;
    }
  }
`;

const ToolCell = styled.div`
  display: flex;
  flex-basis: auto;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonBox = styled.div`
  width: 72px;

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const LoadMoreBox = styled.div`
  width: 100%;
  height: 1px;
`;

const Icon = styled.img`
  display: inline-block;
`;

function getDocumentTypeString(type: DocumentTypeEnum) {
  switch (type) {
    case DocumentTypeEnum.TradeContract:
      return "매매계약서";
    case DocumentTypeEnum.JeonseContract:
      return "전세계약서";
    case DocumentTypeEnum.RentContract:
      return "월세계약서";
    case DocumentTypeEnum.ResidentialManual:
      return "중개대상물 확인설명서 (주거용)";
    default:
      return "-";
  }
}

type Props = {
  busy?: boolean;
  error?: boolean;
  more?: boolean;
  deletingId?: number;
  documents: DocumentListResponse[];
  onLoadMore: () => void;
  onDelete: (documentId: number) => void;
};

const DocumentList: React.FC<Props> = (props) => {
  const [ref, entry] = useIntersect<HTMLDivElement>({
    root: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (entry?.isIntersecting && props.more) {
      props.onLoadMore();
    }
  }, [entry?.isIntersecting]);

  const handleDelete = async (documentId: number) => {
    if (await confirm("이 문서를 삭제할까요? 삭제된 문서는 복구할 수 없습니다.")) {
      props.onDelete(documentId);
    }
  };

  return (
    <DocumentListBox>
      <Container>
        {props.documents.map((document) => {
          const title = document.title.length > 0 ? document.title : document.address_expr || "제목 없음";
          return (
            <DataRow key={document.id}>
              <TitleContainer
                onClick={() => navigate(getUrl("documentsDetailEdit", { params: { documentId: document.id } }))}
              >
                <TypeCell>{getDocumentTypeString(document.document_type)}</TypeCell>
                <Title>{title}</Title>
                <Subtitle>{toKoreanDate(document.created_datetime)}</Subtitle>
              </TitleContainer>
              <ToolCell>
                <ButtonBox>
                  <StyledButton
                    bold
                    outlined
                    height="36px"
                    fontSize="12px"
                    to={getUrl("documentsDetailEdit", { params: { documentId: document.id } })}
                  >
                    문서 수정
                  </StyledButton>
                </ButtonBox>
                <ButtonBox>
                  {document.report_log_id ? (
                    <SaleModalOpenButton
                      as={
                        <StyledButton bold outlined height="36px" fontSize="12px">
                          매물 공유
                        </StyledButton>
                      }
                      saleValues={{ address: document.address_expr, id: document.report_log_id }}
                    />
                  ) : (
                    <StyledButton bold height="36px" fontSize="12px" disabled>
                      매물 공유
                    </StyledButton>
                  )}
                </ButtonBox>
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => handleDelete(document.id)}
                  disabled={document.id === props.deletingId}
                >
                  {document.id === props.deletingId ? <CircularProgress /> : <Icon src={TrashCanImage} />}
                </IconButton>
              </ToolCell>
            </DataRow>
          );
        })}
      </Container>
      {props.busy ? <LoadingCircle /> : <LoadMoreBox ref={ref} />}
    </DocumentListBox>
  );
};

export default DocumentList;
