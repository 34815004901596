import React, { useEffect } from "react";
import { ButtonBase } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import DocumentDownloadList from "../../../components/DocumentDownloadList";
import Modal from "../../../components/Modal";
import { resetLedgers, setReportId } from "@/redux/logs/actions";
import {
  getBuildingLedgerState,
  getLandUsingInfoState,
  getRegisterLedgerState,
  getReportId,
} from "@/redux/logs/selectors";
import { registerLedgerThunk, buildingLedgerThunk, landUsingInfoThunk } from "@/redux/logs/thunk";
import { makeUrlList } from "@/utils/list";

const ModalCloseButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 40px;
    margin-top: 20px;

    color: #333;
    font-weight: 700;
    font-size: 13px;

    background-color: #f2f2f2;
  }
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;

  > button {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;

const StyledModal = styled(Modal)`
  > div {
    padding-top: 0;
  }
`;

interface ReportLogDocumentsDownloadModalProps {
  type?: "normal" | "coporation";
  reportId: number;
  open?: boolean;
  handleClose: () => void;
}

export default function ReportLogDocumentsDownloadModal({
  type,
  reportId,
  open = false,
  handleClose,
}: ReportLogDocumentsDownloadModalProps) {
  const selectedReportId = useSelector(getReportId);
  const dispatch = useDispatch();
  const { value: buildingLedger, fetchState: buildingLedgerFetchState } = useSelector(getBuildingLedgerState);
  const { value: registerLedger, fetchState: registerLedgerFetchState } = useSelector(getRegisterLedgerState);
  const { value: landUsingInfo, fetchState: landUsingInfoFetchState } = useSelector(getLandUsingInfoState);

  useEffect(() => {
    if (!open) {
      return;
    }

    dispatch(resetLedgers());
    dispatch(setReportId(reportId));
    dispatch(registerLedgerThunk(reportId));
    dispatch(buildingLedgerThunk(reportId));
    dispatch(landUsingInfoThunk(reportId));
  }, [open, reportId, selectedReportId]);

  const handleModalClose = () => {
    dispatch(resetLedgers());
    setReportId(null);
    handleClose();
  };

  return (
    <StyledModal
      titleAlign="center"
      open={open}
      title="공문서 다운로드"
      hideAcceptButton
      handleClose={handleModalClose}
      cancelButton={<ModalCloseButton onClick={handleModalClose}>닫기</ModalCloseButton>}
    >
      <ButtonGroupContainer>
        <DocumentDownloadList
          title="등기부등본"
          urls={makeUrlList(registerLedger?.register_ledger_url)}
          fetchState={registerLedgerFetchState}
          reportId={selectedReportId}
          resource="registerLedger"
        />
        {type !== "coporation" && (
          <>
            {buildingLedger?.index_url && (
              <DocumentDownloadList
                title="건축물대장 총괄"
                urls={makeUrlList(buildingLedger?.index_url)}
                fetchState={buildingLedgerFetchState}
                reportId={selectedReportId}
                resource="buildingLedger"
              />
            )}
            {buildingLedger?.complex_url && (
              <DocumentDownloadList
                title="건축물대장 표제부"
                urls={makeUrlList(buildingLedger?.complex_url)}
                fetchState={buildingLedgerFetchState}
                reportId={selectedReportId}
                resource="buildingLedger"
              />
            )}
            <DocumentDownloadList
              title="건축물대장"
              fetchState={buildingLedger?.primary_urls || buildingLedger?.unit_url ? "SUCCESS" : "FAILURE"}
              urls={makeUrlList(buildingLedger?.primary_urls, buildingLedger?.unit_url)}
              reportId={selectedReportId}
              resource="buildingLedger"
            />
            <DocumentDownloadList
              title="토지이용계획"
              urls={makeUrlList(landUsingInfo?.land_using_info_url)}
              fetchState={landUsingInfoFetchState}
              reportId={selectedReportId}
              resource="landUsingInfo"
            />
          </>
        )}
      </ButtonGroupContainer>
    </StyledModal>
  );
}
