import React from "react";
import PostLayout from "@/features/posts/components/common/PostLayout";
import PostTypeSelect from "@/features/posts/components/PostTypeSelect";
import PostListContainer from "@/features/posts/containers/PostListContainer";
import PostTitledContainer from "@/features/posts/containers/PostTitledContainer";

const Posts = () => {
  return (
    <PostLayout>
      <PostTitledContainer />
      <PostTypeSelect />
      <PostListContainer />
    </PostLayout>
  );
};

export default Posts;
