import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import CardButton from "@/components/buttons/CardButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CardButtonContainer from "@/components/CardButtonContainer";
import Link from "@/components/Link";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";

const UserVerifyButtonsContainer = () => {
  const userMe = useSelector(getUserMe);

  const identityVerifyButton = useMemo(() => {
    if (userMe?.identity_verified) {
      return <CardButton disabled>실명인증(완료)</CardButton>;
    }

    return (
      <Link href={getUrl("userVerifyIdentity")}>
        <CardButton>실명인증</CardButton>
      </Link>
    );
  }, [userMe]);

  const realtorVerifyButton = useMemo(() => {
    if (userMe?.realtor_verified) {
      return <PrimaryButton disabled>공인중개사 인증(완료)</PrimaryButton>;
    }

    return (
      <Link href={getUrl("userVerifyRealtor")}>
        <PrimaryButton>공인중개사 인증</PrimaryButton>
      </Link>
    );
  }, [userMe]);

  return (
    <CardButtonContainer>
      {identityVerifyButton}
      {realtorVerifyButton}
    </CardButtonContainer>
  );
};

export default UserVerifyButtonsContainer;
