import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import FlatButton from "@/components/buttons/FlatButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { TextFieldInnerIconButton } from "@/components/buttons/TextFieldInnerButton";
import SearchTypeSelect, { ExtendSearchType } from "@/components/dropdown/SearchTypeSelect";
import Link from "@/components/Link";
import { TextFieldWithInnerButton } from "@/components/TextField";
import TitledContainer from "@/components/TitledContainer";
import { CorporationReportLogList } from "@/containers/corporation/CorporationReportLogList";
import { ReportLogList } from "@/features/reports/components/ReportLog";
import ReportLogCategorySelect from "@/features/user/components/logs/ReportLogCategorySelect";
import { AddressSearchContainer } from "@/features/user/components/logs/userIssuedReportListLayout";
import {
  SearchCategorySelectContainer,
  SearchQueryResultContainer,
  SearchTypeSelectContainer,
} from "@/features/user/components/logs/userReportListLayout";
import UserCommonLayout from "@/features/user/components/UserCommonLayout";
import useWindowSize from "@/hooks/useWindowSize";
import { resetCorporationReportLogListState, resetCorporationReportLogState } from "@/redux/corporation/actions";
import { getCorporationReportLogListState } from "@/redux/corporation/selectors";
import { fetchMoreCorporationReportLogListThunk } from "@/redux/corporation/thunk";
import {
  resetReportActionState,
  resetReportLogListState,
  resetReportLogTrashListState,
  setSearchCategory,
  setSearchType,
} from "@/redux/logs/actions";
import {
  getDeleteReportLogFetchState,
  getReportLogListState,
  getSearchCategory,
  getSearchType,
} from "@/redux/logs/selectors";
import { fetchMoreReportLogListThunk } from "@/redux/logs/thunk";
import { SearchCategoriesType, SearchType } from "@/redux/logs/types";
import { lengthsInNumbers } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const SEARCH_CATEGORY_MAP: Record<SearchCategoriesType, string> = {
  normal: "부동산",
  corporation: "법인",
};

interface ReportListPageProps {}

export default function ReportListPage(props: ReportListPageProps) {
  const {
    value: reportLogList,
    fetchState: reportLogListFetchState,
    page: reportLogListPage,
    totalPage: reportLogListTotalPage,
    totalCount: reportLogListTotalCount,
  } = useSelector(getReportLogListState);

  const {
    value: corporationReportLogList,
    fetchState: corporationReportLogListFetchState,
    page: corporationReportLogListPage,
    totalPage: corporationReportLogListTotalPage,
  } = useSelector(getCorporationReportLogListState);

  const deleteReportLogFetchState = useSelector(getDeleteReportLogFetchState);

  const searchType = useSelector(getSearchType);
  const searchCategory = useSelector(getSearchCategory);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramQuery = searchParams.get("query") ?? void 0;
  const [searched, setSearched] = useState(false);
  const [query, setQuery] = useState(paramQuery);
  const { width } = useWindowSize();
  const [pendingCount, setPendingCount] = useState(0);

  const dispatch = useDispatch();

  const handleLoadMoreReportLogList = useCallback(() => {
    if (reportLogListFetchState === "FETCHING") {
      return;
    }

    dispatch(fetchMoreReportLogListThunk({ registeredAddress: query, deleted: false }));
  }, [reportLogListFetchState, query]);

  const handleLoadMoreCorporationReportLogList = useCallback(() => {
    if (corporationReportLogListFetchState === "FETCHING") {
      return;
    }

    dispatch(fetchMoreCorporationReportLogListThunk(query ? { companyName: query } : {}));
  }, [corporationReportLogListFetchState, query]);

  const handleResetAndLoadReportLogList = useCallback(() => {
    dispatch(resetReportLogTrashListState());
    dispatch(resetReportActionState());
    dispatch(resetCorporationReportLogState());

    if (reportLogListFetchState === "FETCHING" || corporationReportLogListFetchState === "FETCHING") {
      return;
    }

    dispatch(resetReportLogListState());
    dispatch(fetchMoreReportLogListThunk({ registeredAddress: query, deleted: false }));
    dispatch(resetCorporationReportLogListState());
    dispatch(fetchMoreCorporationReportLogListThunk(query ? { companyName: query } : {}));
  }, [reportLogListFetchState, corporationReportLogListFetchState, query]);

  const handleResetAndLoadCoporationReportLogList = useCallback(() => {
    if (corporationReportLogListFetchState === "FETCHING") {
      return;
    }

    dispatch(resetCorporationReportLogListState());
    dispatch(fetchMoreCorporationReportLogListThunk({ companyName: query || void 0 }));
  }, [corporationReportLogListFetchState, query]);

  useEffect(() => {
    if (!["address", "memo"].includes(searchType)) {
      dispatch(setSearchType("address"));
    }
    dispatch(setSearchCategory("normal"));
    handleResetAndLoadReportLogList();
  }, []);

  useEffect(() => {
    if (paramQuery) {
      handleResetAndLoadReportLogList();
    }
  }, [paramQuery]);

  useEffect(() => {
    if (deleteReportLogFetchState !== "SUCCESS") {
      return;
    }

    handleResetAndLoadReportLogList();
  }, [deleteReportLogFetchState]);

  useEffect(() => {
    handleLoadMoreCorporationReportLogList();
  }, []);

  const recurSetPendingCount = async () => {
    dispatch(fetchMoreReportLogListThunk({ deleted: false }));
    let count = 0;
    reportLogList?.forEach((report) => {
      if (report.is_pending === true) {
        count += 1;
      }
    });
    setPendingCount(count);
    if (count !== 0) {
      setTimeout(() => {
        recurSetPendingCount();
      }, 10000);
    } else {
      dispatch(resetReportLogListState());
      dispatch(fetchMoreReportLogListThunk({ deleted: false }));
    }
  };

  useEffect(() => {
    recurSetPendingCount();
  }, []);

  useEffect(() => {
    if (pendingCount !== 0) {
      let count = 0;
      reportLogList?.forEach((report) => {
        if (report.is_pending === true) {
          count += 1;
        }
      });
      if (count !== pendingCount) {
        dispatch(resetReportLogListState());
        dispatch(fetchMoreReportLogListThunk({ deleted: false }));
      }
    }
  }, [pendingCount]);

  const handleQueryReset = () => {
    setSearched(false);
    navigate(getUrl("reportsRoot"));
    setQuery("");
    dispatch(resetReportLogListState());
    dispatch(fetchMoreReportLogListThunk({ registeredAddress: query, deleted: false }));
  };

  const handleQueryTextFieldReset = () => {
    setQuery("");
  };

  const handleSearch = useCallback(() => {
    if (!query) {
      handleQueryReset();
    } else {
      setSearched(true);
      setSearchParams({ query });
    }

    if (searchCategory === "normal") {
      handleResetAndLoadReportLogList();
    } else if (searchCategory === "corporation") {
      handleResetAndLoadCoporationReportLogList();
    }
  }, [query, paramQuery]);

  useEffect(() => {
    setQuery(void 0);
    if (searchCategory === "normal") {
      dispatch(resetReportLogListState());
      dispatch(fetchMoreReportLogListThunk({ deleted: false }));
    } else {
      dispatch(resetCorporationReportLogListState());
      dispatch(fetchMoreCorporationReportLogListThunk(query ? { companyName: query } : {}));
    }
  }, [searchCategory]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleSearch();
  };

  const handleQueryChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(value);
  };

  const searchTypeHelperText = useMemo(() => {
    if (searchCategory === "corporation") {
      return "(한글자 이상)";
    }
    if (searchType === "address") {
      return "(지역, 아파트 이름)";
    } else if (searchType === "memo") {
      return "(메모)";
    }
  }, [searchCategory, searchType]);

  const handleSearchType = (type: ExtendSearchType) => {
    dispatch(setSearchType(type as SearchType));
    setQuery("");
    dispatch(resetReportLogListState());
    dispatch(fetchMoreReportLogListThunk({ deleted: false }));
  };

  return (
    <UserCommonLayout>
      <SearchCategorySelectContainer>
        <ReportLogCategorySelect />
      </SearchCategorySelectContainer>
      <TitledContainer
        title={
          <>
            {SEARCH_CATEGORY_MAP[searchCategory]} 열람내역{" "}
            {paramQuery && (
              <SearchQueryResultContainer>
                '{paramQuery}' 검색 결과: {reportLogListTotalCount}개
              </SearchQueryResultContainer>
            )}
          </>
        }
        actions={
          searchCategory === "normal" && (
            <Link href={getUrl("reportsTrash")}>
              <FlatButton>삭제 내역 보기</FlatButton>
            </Link>
          )
        }
      />
      {searchCategory === "normal" && (
        <SearchTypeSelectContainer>
          <SearchTypeSelect
            items={[
              { key: "address", name: "주소" },
              { key: "memo", name: "메모" },
            ]}
            defaultValue={["address", "memo"].includes(searchType) ? searchType : "address"}
            onTypeChange={handleSearchType}
          />
        </SearchTypeSelectContainer>
      )}
      <AddressSearchContainer onSubmit={handleSubmit}>
        <TextFieldWithInnerButton
          fullWidth
          type="text"
          placeholder={
            width >= lengthsInNumbers.desktop ? `열람내역 키워드 검색 ${searchTypeHelperText}` : "열람내역 키워드 검색"
          }
          value={query}
          onChange={handleQueryChange}
          innerButton={
            <TextFieldInnerIconButton onClick={handleQueryTextFieldReset}>
              <Close />
            </TextFieldInnerIconButton>
          }
          innerButtonWidth={40}
        />
        <PrimaryButton onClick={handleSearch}>{searched ? "재검색" : "검색"}</PrimaryButton>
      </AddressSearchContainer>
      {searchCategory === "normal" && (
        <ReportLogList
          list={reportLogList}
          fetchState={reportLogListFetchState}
          page={reportLogListPage}
          totalPage={reportLogListTotalPage}
          onLoadMore={handleLoadMoreReportLogList}
          query={paramQuery}
        />
      )}
      {searchCategory === "corporation" && (
        <CorporationReportLogList
          list={corporationReportLogList}
          fetchState={corporationReportLogListFetchState}
          page={corporationReportLogListPage}
          totalPage={corporationReportLogListTotalPage}
          onLoadMore={handleLoadMoreCorporationReportLogList}
          query={paramQuery}
        />
      )}
    </UserCommonLayout>
  );
}
