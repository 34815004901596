import React from "react";
import { useSelector } from "react-redux";
import ReportAddressInfo from "@/features/reports/components/ReportAddressInfo";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportAddressInfoContainerProps {}

const ReportAddressInfoContainer: React.FC<ReportAddressInfoContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  return <ReportAddressInfo report={report} reportFetchState={reportFetchState} />;
};

export default ReportAddressInfoContainer;
