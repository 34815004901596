import React from "react";
import { ReportLogUserRelationResponse, UserResponse } from "@doczip/api-client";
import styled from "styled-components";
import LandingAbout from "./LandingAbout";
import LandingVisual from "./LandingVisual";
import RandomRegisterLedger from "./RandomRegisterLedger";
import AddressListWithSearchContainer from "@/containers/AddressListWithSearchContainer";
import OwnersClubFreeTrialBanner from "@/features/owners-club/components/OwnersClubFreeTrialBanner";
import { FetchState } from "@/interfaces/fetch";
import { lengths, lengthsInNumbers } from "@/styles/values";

const DesktopLandingContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${lengthsInNumbers.headerHeight + lengthsInNumbers.footerHeight}px);

  background-color: #fff;
`;

const DekstopLandingContentContainer = styled.div`
  width: min(${lengths.containerWidth}, calc(100vw - 96px));
  height: 100%;
  margin: 0 auto;
  padding-top: 40px;
`;

const DividedFrame = styled.div`
  display: flex;
`;

const Frame = styled.div`
  width: 50%;
  max-height: 33.75rem;

  &:nth-of-type(1) {
    padding: 4.5rem 0;
  }
`;

const DesktopLandingInnerContainer = styled.div`
  margin-top: 1.4375rem;
`;

const StyledOwnersClubFreeTrialBanner = styled(OwnersClubFreeTrialBanner)`
  margin-bottom: 40px;

  border: 1px solid #dfe2e7;
`;

interface DesktopLandingProps {
  userMe: UserResponse | null;
  randomReport: ReportLogUserRelationResponse[] | null;
  randomReportFetchState: FetchState;
}

const DesktopLanding: React.FC<DesktopLandingProps> = ({ userMe, randomReport, randomReportFetchState }) => {
  return (
    <DesktopLandingContainer>
      <DekstopLandingContentContainer>
        {userMe && userMe.subscription?.subscription_type ? null : <StyledOwnersClubFreeTrialBanner />}
        <DividedFrame>
          <Frame>
            <LandingAbout />
            <AddressListWithSearchContainer
              multiSelect
              textFieldPlaceholder="주소 입력하면 바로 등기부등본 발급을 한번에!"
              focusedTextFieldPlaceholder="도로명 주소, 건물명 또는 지번 입력"
              subButtonText="시작하기"
              focusedSubButtonText="검색하기"
              withDefaultPrefix
              withAnimation
            />
          </Frame>
          <Frame>
            <LandingVisual />
          </Frame>
        </DividedFrame>

        <DesktopLandingInnerContainer>
          <RandomRegisterLedger randomReport={randomReport} randomReportFetchState={randomReportFetchState} />
        </DesktopLandingInnerContainer>
      </DekstopLandingContentContainer>
    </DesktopLandingContainer>
  );
};

export default DesktopLanding;
