import React, { useCallback, useEffect, useState } from "react";
import { PostType, UserType } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import PostList from "@/features/posts/components/PostList";
import useQuery from "@/hooks/useQuery";
import { resetLimitedPostListState } from "@/redux/posts/actions";
import {
  getCreatePostFetchState,
  getDeletePostFetchState,
  getLimitedPostList,
  getLimitedPostListFetchState,
  getLimitedPostListPage,
  getLimitedPostListTotalPage,
} from "@/redux/posts/selectors";
import { fetchMoreLimitedPostListThunk } from "@/redux/posts/thunk";
import { getUserMe } from "@/redux/user/selectors";

const PostListContainer = () => {
  const user = useSelector(getUserMe);
  const query = useQuery();
  const [postTypes, setPostTypes] = useState<PostType[]>([]);
  const limitedPostList = useSelector(getLimitedPostList);
  const limitedPostListFetchState = useSelector(getLimitedPostListFetchState);
  const limitedPostListPage = useSelector(getLimitedPostListPage);
  const limitedPostListTotalPage = useSelector(getLimitedPostListTotalPage);
  const deletePostFetchState = useSelector(getDeletePostFetchState);
  const createPostFetchState = useSelector(getCreatePostFetchState);

  const dispatch = useDispatch();

  const options = user?.user_type === UserType.Admin ? { postTypes, includeHiddenPosts: true } : { postTypes };

  useEffect(() => {
    dispatch(resetLimitedPostListState());
  }, [postTypes]);

  useEffect(() => {
    if (deletePostFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetLimitedPostListState());
  }, [deletePostFetchState]);

  useEffect(() => {
    if (createPostFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetLimitedPostListState());
    dispatch(fetchMoreLimitedPostListThunk(options));
  }, [createPostFetchState]);

  const handleLoadMoreLimitedPostList = useCallback(() => {
    if (limitedPostListFetchState === "FETCHING") {
      return;
    }
    if (limitedPostList && limitedPostList.length < 10) {
      return;
    }

    dispatch(fetchMoreLimitedPostListThunk(options));
  }, [limitedPostListFetchState, postTypes]);

  useEffect(() => {
    const type = query.get("type");
    const newPostTypes: PostType[] = [];
    if (type !== null) {
      const types = type.split(",");
      types.forEach((type) => newPostTypes.push(type as PostType));
    }
    setPostTypes(newPostTypes);
  }, [query]);

  return (
    <PostList
      list={limitedPostList}
      fetchState={limitedPostListFetchState}
      page={limitedPostListPage}
      totalPage={limitedPostListTotalPage}
      onLoadMore={handleLoadMoreLimitedPostList}
    />
  );
};

export default PostListContainer;
