import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ReportOwnerInfo from "@/features/reports/components/ReportOwnerInfo";
import { getIssuedReportLogCountState } from "@/redux/logs/selectors";
import { getReport, getReportFetchState } from "@/redux/report/selectors";
import { getUserMe } from "@/redux/user/selectors";

interface ReportOwnerInfoContainerProps {}

const ReportOwnerInfoContainer: React.FC<ReportOwnerInfoContainerProps> = () => {
  const user = useSelector(getUserMe);
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);
  const issuedReportLogCountState = useSelector(getIssuedReportLogCountState);

  const registerLedger = useMemo(() => {
    if (!report || !report?.report || !report?.report?.register_ledger) {
      return;
    }

    const {
      report: { register_ledger },
    } = report;

    return register_ledger;
  }, [report, reportFetchState]);

  const count = useMemo(() => {
    if (!issuedReportLogCountState || !issuedReportLogCountState?.value) {
      return;
    }

    return issuedReportLogCountState.value;
  }, [issuedReportLogCountState]);

  return (
    <ReportOwnerInfo
      user={user}
      registerLedger={registerLedger}
      reportFetchState={reportFetchState}
      issuedReportLogCount={count}
    />
  );
};

export default ReportOwnerInfoContainer;
