import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TitledContainer from "@/components/TitledContainer";
import SaleGradientSlider from "@/features/sales/components/SaleGradientSlider";
import {
  getLimitedSaleList,
  getLimitedSaleListFetchState,
  getLimitedSaleListPage,
  getLimitedSaleListTotalPage,
} from "@/redux/sales/selectors";
import { fetchMoreLimitedSaleListThunk } from "@/redux/sales/thunk";
import { getUrl } from "@/utils/routes";

const SaleTitledOuterContainer = styled.div`
  padding-bottom: 30px;
`;

const SaleGradientSlideContainer = () => {
  const limitedSaleList = useSelector(getLimitedSaleList);
  const limitedSaleListFetchState = useSelector(getLimitedSaleListFetchState);
  const limitedSaleListPage = useSelector(getLimitedSaleListPage);
  const limitedSaleListTotalPage = useSelector(getLimitedSaleListTotalPage);

  const dispatch = useDispatch();

  const handleLoadMoreLimitedSaleList = () => {
    dispatch(fetchMoreLimitedSaleListThunk());
  };

  return (
    <SaleTitledOuterContainer>
      <TitledContainer
        title="최근 등록 매물"
        titleFontSize={14}
        actions={
          <Link to={getUrl("salesRoot")}>
            <div style={{ fontWeight: 400, fontSize: 13 }}>전체보기</div>
          </Link>
        }
      />
      <SaleGradientSlider
        list={limitedSaleList}
        fetchState={limitedSaleListFetchState}
        page={limitedSaleListPage}
        totalPage={limitedSaleListTotalPage}
        onLoadMore={handleLoadMoreLimitedSaleList}
      />
    </SaleTitledOuterContainer>
  );
};

export default SaleGradientSlideContainer;
