import { format as dateFnsFormat, formatDistanceToNow, isValid, differenceInDays, isSameDay } from "date-fns";
import { ko } from "date-fns/locale";

function normalizeDate(rawDate?: Date | string | number) {
  switch (typeof rawDate) {
    case "undefined": {
      return;
    }
    case "string":
    case "number":
    case "object": {
      const date = typeof rawDate === "object" ? rawDate : new Date(rawDate);

      if (!isValid(date)) {
        return;
      }

      return date;
    }
  }
}

export function getExpirationMessage(rawDate: Date | string | number | undefined): string {
  const date = normalizeDate(rawDate);
  const now = new Date();

  if (!date) {
    return "유효하지 않은 날짜";
  }

  if (isSameDay(date, now)) {
    return "알림 종료";
  }

  const difference = differenceInDays(date, now);

  if (difference <= 0) {
    return "알림 종료";
  } else {
    return `만기 ${difference}일 전`;
  }
}

export function format(rawDate: Date | string | number | undefined, formatString: string) {
  const date = normalizeDate(rawDate);

  if (!date) {
    return "";
  }

  return dateFnsFormat(date, formatString, { locale: ko });
}

export function toFromNow(rawDate?: Date | string | number) {
  const date = normalizeDate(rawDate);

  if (!date) {
    return "";
  }

  return formatDistanceToNow(date);
}

export function toLongDateTime(rawDate?: Date | string | number) {
  return format(rawDate, "LLL");
}

export function toLongDate(rawDate?: Date | string | number) {
  return format(rawDate, "LL");
}

interface toKoreanDateOptions {
  use12HourFormat?: boolean;
}

export function toKoreanDate(rawDate?: Date | string | number, { use12HourFormat }: toKoreanDateOptions = {}) {
  const date = normalizeDate(rawDate);

  if (!date) {
    return "";
  }

  const amPm = format(rawDate, "aaaaa") === "a" ? "오전" : "오후";

  const formattedString = format(rawDate, `yyyy년 M월 d일 ${use12HourFormat ? `${amPm} hh` : "HH"}:mm`);

  return formattedString;
}

export function toYmd(rawDate?: Date | string | number, options?: { useIsoFormat?: boolean }) {
  return format(rawDate, options?.useIsoFormat ? "yyyy-MM-dd" : "yyyy.MM.dd");
}

export function toIso(rawDate?: Date | string | number) {
  return format(rawDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export function getTodayDateString() {
  const offset = new Date().getTimezoneOffset() * 60000;
  const today = new Date(Date.now() - offset);
  return today.toISOString().substring(0, 16);
}

export function getTomorrowDateString() {
  const offset = new Date().getTimezoneOffset() * 60000;
  const today = new Date(Date.now() - offset);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().substring(0, 16);
}
