import React from "react";
import { useSelector } from "react-redux";
import OwnersClubFreeTrialBanner from "@/features/owners-club/components/OwnersClubFreeTrialBanner";
import { getUserMe } from "@/redux/user/selectors";

const OwnersClubFreeTrialBannerContainer = () => {
  const user = useSelector(getUserMe);

  return <>{user && user.subscription?.subscription_type ? null : <OwnersClubFreeTrialBanner />}</>;
};

export default OwnersClubFreeTrialBannerContainer;
