import React, { useEffect, useState } from "react";
import { SaleResponse, UserResponse } from "@doczip/api-client";
import { Alert } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Dialog from "@/components/Dialog";
import LoadingCircle from "@/components/LoadingCircle";
import Map from "@/components/Map";
import TitledWrapper from "@/components/TitledWrapper";
import NaverMapLoader from "@/containers/NaverMapLoader";
import SaleImageSlide from "@/features/sales/components/common/SaleImageSlide";
import SaleModalOpenButton from "@/features/sales/components/SaleModalOpenButton";
import SaleCommentListContainer from "@/features/sales/containers/SaleCommentListContainer";
import { FetchState } from "@/interfaces/fetch";
import { colors, lengths } from "@/styles/values";
import { toYmd } from "@/utils/datetime";
import { getCoordinates } from "@/utils/map";
import { getUrl } from "@/utils/routes";
import { addSubfix, convertTradeTypeToString, numberToKorean } from "@/utils/sales";

const MapContainer = styled.div`
  max-width: 580px;
`;

const Container = styled.div`
  max-width: 816px;
`;

const HeadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;

    text-align: left;
  }
`;

const Date = styled.div`
  color: #797979;
  font-weight: 400;
  font-size: 12px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;

    font-size: 12px;
    text-align: left;
  }
`;

const DetailContent = styled.div`
  padding-bottom: 12px;
`;

const DetailRow = styled.div`
  font-weight: 400;
  font-size: 13px;
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const User = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const SaleMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;

  color: ${colors.black};
  font-weight: 400;
  font-size: 12px;

  background-color: inherit;
  border: 1px solid #dfe2e7;
  cursor: pointer;
`;

const MainContent = styled.div`
  min-height: 128px;
  margin: 20px 0;
  padding: 20px 0;

  font-weight: 400;
  font-size: 13px;

  border-top: 1px solid #dfe2e7;
  border-bottom: 1px solid #dfe2e7;
`;

const DescriptionsContainer = styled.div`
  & img {
    display: none;
  }

  & p {
    margin: 0;
    padding: 0;

    line-height: 22px;
    letter-spacing: -0.5px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StrongFont = styled.span`
  font-weight: 600;
`;

type SaleShowProps = {
  sale: SaleResponse | null;
  user: UserResponse | null;
  fetchState: FetchState;
  onDeleteSale: () => void;
  deleteSaleFetchState: FetchState;
};

const SaleShow = ({ sale, user, onDeleteSale, deleteSaleFetchState, fetchState }: SaleShowProps) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [coords, setCoords] = useState<[string, string] | null>(null);

  useEffect(() => {
    if (deleteSaleFetchState === "SUCCESS") {
      navigate(getUrl("salesRoot"));
    }
  }, [deleteSaleFetchState]);

  useEffect(() => {
    async function getCoords(address: string) {
      const datas = await getCoordinates(address);
      setCoords(datas);
    }
    if (sale && sale.report_log.registered_address) {
      getCoords(sale.report_log.registered_address);
    }
  }, [sale]);

  const handleCallButtonClick = () => {
    setShowDialog(true);
  };

  if (sale && fetchState === "SUCCESS") {
    return (
      <NaverMapLoader>
        <Container>
          <HeadContent>
            <Title>{sale.report_log.registered_address}</Title>
            <Date>{toYmd(sale.created_datetime)}</Date>
          </HeadContent>
          <DetailContent>
            {sale.supply_area || sale.exclusive_area ? (
              <DetailRow>
                <StrongFont>면적</StrongFont> {addSubfix(sale.supply_area, "㎡")}{" "}
                {sale.supply_area && sale.exclusive_area && "/"}
                {addSubfix(sale.exclusive_area, "㎡")}
                {sale.supply_area &&
                  sale.exclusive_area &&
                  "전용률(" + Math.floor((sale.exclusive_area / sale.supply_area) * 100) + "%)"}
              </DetailRow>
            ) : null}
            {sale.floor && (
              <DetailRow>
                <StrongFont>층수</StrongFont> {addSubfix(sale.floor, "층")}
              </DetailRow>
            )}
            {false && (
              <DetailRow>
                <StrongFont>해당면적 세대수</StrongFont> 10세대
              </DetailRow>
            )}
            <DetailRow>
              <StrongFont>주소</StrongFont> {sale.report_log.registered_address}
            </DetailRow>
          </DetailContent>
          <Price>
            {convertTradeTypeToString(sale.trade_type)} {numberToKorean(sale.price)}
            {sale.rent_price && "/" + numberToKorean(sale.rent_price)} 원
          </Price>
          <MainContent>
            {coords && (
              <TitledWrapper
                title="위치"
                titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
                backgroundStyle={{ color: "#ffffff" }}
              >
                <MapContainer>
                  <Map latitude={coords[1]} longitude={coords[0]} />
                </MapContainer>
              </TitledWrapper>
            )}
            {sale.image_sets.length > 0 ? <SaleImageSlide imageSet={sale.image_sets} /> : null}
            <DescriptionsContainer
              dangerouslySetInnerHTML={{
                __html: sale.descriptions,
              }}
            />
          </MainContent>
          <SaleCommentListContainer saleId={sale?.id} user={user} />
          <FooterContainer>
            <User>{sale.user.nickname ? sale.user.nickname : `유저(${sale.user.username})`}</User>
            <ButtonsContainer>
              {user && user.id === sale.user.id ? (
                <LeftButtonsContainer>
                  <SaleModalOpenButton as={<SaleMenuButton>수정하기</SaleMenuButton>} saleValues={sale} />
                  <SaleMenuButton onClick={onDeleteSale} disabled={deleteSaleFetchState === "FETCHING"}>
                    삭제하기
                  </SaleMenuButton>
                </LeftButtonsContainer>
              ) : (
                <LeftButtonsContainer>
                  {showDialog && (
                    <Dialog
                      title="전화 연결을 하시겠습니까?"
                      content={`${sale.contact_phone_number}로 전화 연결하시겠습니까?`}
                      onConfirm={() => window.open(`tel:${sale.contact_phone_number}`)}
                      onClose={() => setShowDialog(false)}
                      confirmText="전화하기"
                      closeText="다음에"
                    />
                  )}
                  <SaleMenuButton onClick={handleCallButtonClick}>전화하기</SaleMenuButton>
                  <SaleMenuButton onClick={() => window.open(`sms:${sale.contact_phone_number}`)}>
                    문자하기
                  </SaleMenuButton>
                </LeftButtonsContainer>
              )}
              <Link to={getUrl("salesRoot")}>
                <SaleMenuButton>목록</SaleMenuButton>
              </Link>
            </ButtonsContainer>
          </FooterContainer>
        </Container>
      </NaverMapLoader>
    );
  } else if ((!sale && fetchState === "READY") || fetchState === "FETCHING") {
    return <LoadingCircle position="absolute" />;
  } else {
    return (
      <Alert severity="warning">매물 공유 정보를 가져오는 도중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.</Alert>
    );
  }
};

export default SaleShow;
