import React from "react";
import { useSelector } from "react-redux";
import MobileLanding from "@/features/landing/components/mobile/MobileLanding";
import { getLimitedPostList, getLimitedPostListFetchState } from "@/redux/posts/selectors";

interface MobileLandingContainerProps {}

const MobileLandingContainer: React.FC<MobileLandingContainerProps> = () => {
  const posts = useSelector(getLimitedPostList);
  const postsFetchState = useSelector(getLimitedPostListFetchState);

  return <MobileLanding posts={posts} postsFetchState={postsFetchState} />;
};

export default MobileLandingContainer;
