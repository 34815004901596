import React, { useEffect, useMemo, useRef, useState } from "react";
import { CreateSaleRequest, SaleImageSetResponse, SaleResponse, TradeType, UserResponse } from "@doczip/api-client";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { InputAdornment } from "@mui/material";
import styled from "styled-components";
import { saleApi } from "@/client";
import { StyledReactQuill } from "@/components/editor/TextEditor";
import Modal from "@/components/modal/Modal";
import { Button, TextField } from "@/components/Mui";
import TradeTypeSelect from "@/components/TradeTypeSelect";
import Configs from "@/configs";
import { ReportInfo } from "@/features/sales/components/common/SaleType";
import ReportSelect from "@/features/sales/components/ReportSelect";
import useSale, { SaleNewFormValues } from "@/features/sales/hooks/useSale";
import { colors, lengths } from "@/styles/values";
import { alert, confirm } from "@/utils/dialog";
import { isEmptyObj } from "@/utils/sales";

const Form = styled.form``;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 560px;
  padding-bottom: 20px;

  @media screen and (max-width: ${lengths.desktop}) {
    width: 100%;
  }
`;

const PriceField = styled(TextField)`
  & .MuiOutlinedInput-input {
    font-size: 1rem;

    background-color: ${colors.white};
    border: 0;

    ::-webkit-inner-spin-button {
      margin: 0;

      appearance: none;
    }
    ::-webkit-outer-spin-button {
      margin: 0;

      appearance: none;
    }

    appearance: textfield;
  }
`;

const MonetaryUnit = styled.div`
  padding-right: 10px;
`;

const RentAdornment = styled.div`
  font-size: 13px;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const ImageUploadContainer = styled.div`
  position: relative;
`;

const ImageBoxContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 12px;
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FlexBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;

  cursor: pointer;
`;

const ImagesBox = styled.div`
  z-index: 2;

  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  z-index: 3;

  width: 36px;
  height: 36px;

  border-radius: 4px;
  cursor: pointer;
`;

const FileInput = styled.input``;

const ImageModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SelectedImage = styled.img`
  max-height: 500px;
  padding: 40px 0;

  @media screen and (max-width: ${lengths.desktop}) {
    margin: 0 auto;
    padding: 16px 0;
  }
`;

const HelperText = styled.p`
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;

  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
`;

type SaleNewProps = {
  user: UserResponse | null;
  open: boolean;
  saveSale: (createSaleRequest: CreateSaleRequest) => void;
  handleClose: () => void;
  saleValues?: SaleResponse | ReportInfo;
};

const SaleNew = ({ user, open, saveSale, handleClose, saleValues }: SaleNewProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [defaultReport, setDefaultReport] = useState<ReportInfo>();
  const [showForm, setShowForm] = useState(true);
  const [selectedImage, setSelectedImage] = useState<SaleImageSetResponse | null>(null);

  const initialValues: SaleNewFormValues = useMemo(() => {
    if (saleValues) {
      return {
        ...(saleValues as any as SaleNewFormValues),
        report_id: saleValues.id,
        title: "address" in saleValues ? saleValues.address : saleValues.report_log.registered_address,
        trade_type: TradeType.Deal,
        price: 0,
        descriptions: "",
        available_for_immediate_move_in: true,
        contact_phone_number:
          (user && user.detail && user.detail.phone_number && user?.detail?.phone_number) ?? "010-0000-0000",
      };
    } else {
      return {
        report_id: null,
        trade_type: TradeType.Deal,
        price: 0,
        available_for_immediate_move_in: true,
        descriptions: "",
        contact_phone_number:
          (user && user.detail && user.detail.phone_number && user?.detail?.phone_number) ?? "010-0000-0000",
        image_set_ids: [],
      };
    }
  }, [saleValues]);

  const {
    values,
    getFieldProps,
    setFieldValue,
    submitForm,
    handleSubmit,
    errors,
    handleBlur,
    resetForm,
    imageSet,
    setImageSet,
  } = useSale({ initialValues, saveSale });

  const onSubmitForm = async () => {
    await submitForm();
    if (!isEmptyObj(errors)) {
      setShowForm(false);
      await alert("에러 메세지를 확인해주세요.");
      setShowForm(true);
    } else {
      handleClose();
    }
  };

  const handleModalClose = async () => {
    setShowForm(false);
    const temp: any = values;
    delete temp.image_set_ids;

    let diffCheck = false;

    Object.keys(initialValues).forEach((key, index) => {
      if (temp[key] !== (initialValues[key] as any)) {
        diffCheck = true;
      }
    });

    if (diffCheck) {
      if (
        !(await confirm("작성한 내용이 저장되지 않습니다. 그래도 닫으시겠습니까?", {
          title: "닫으시겠습니까?",
          confirmText: "창 닫기",
          closeText: "되돌아가기",
        }))
      ) {
        setShowForm(true);
        return;
      } else {
        setShowForm(true);
      }
    }

    handleClose();
  };

  useEffect(() => {
    if (saleValues) {
      if ("address" in saleValues) {
        setDefaultReport(saleValues);
        setFieldValue("report_id", saleValues.id);
        setFieldValue("title", saleValues.address);
      } else {
        resetForm({
          values: {
            ...(saleValues as any as SaleNewFormValues),
          },
        });

        if (saleValues.image_sets.length > 0) {
          const imageIdList: number[] = [];
          const imageUrlList: string[] = [];
          saleValues.image_sets.forEach((image: SaleImageSetResponse) => {
            imageIdList.push(image.id);
            imageUrlList.push(Configs.DOCZIP_REST_API_URL + image.thumbnail_image_path);
          });
          setFieldValue("image_set_ids", [...imageIdList]);
          setImageSet([...saleValues.image_sets]);
        }

        setFieldValue("report_id", saleValues.id);
        setFieldValue("title", saleValues.report_log.registered_address);
        setDefaultReport({ address: saleValues.report_log.registered_address, id: saleValues.report_log.id });
      }
    }
  }, [saleValues]);

  const handleTypeChange = (type: TradeType) => {
    setFieldValue("trade_type", type);
  };

  const handleReportChange = async (reportInfo: ReportInfo | null) => {
    await setFieldValue("report_id", reportInfo?.id);
    await setFieldValue("title", reportInfo?.address);
  };

  const handleImageSetChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = e.target.files ? Array.from(e.target.files) : [];
      if (files.length > 0) {
        files.forEach(async (file: any) => {
          const res = await saleApi.uploadImage(file);
          setImageSet((prev) => [...prev, res.data]);
        });
      } else {
        alert("이미지 업로드를 취소하셨습니다.");
      }
    } catch (err) {
      await alert("이미지 업로드 중에 오류가 발생했습니다. 잠시 후에 다시 시도해주세요.");
    }
  };

  return (
    <>
      {selectedImage && (
        <Modal
          open={true}
          handleClose={() => setSelectedImage(null)}
          acceptButtonText="제거하기"
          handleAccept={() => {
            setImageSet((prev) => prev.filter((set) => set.id !== selectedImage.id));
            setSelectedImage(null);
          }}
        >
          <ImageModalContainer>
            <SelectedImage src={Configs.DOCZIP_REST_API_URL + selectedImage.medium_image_path} />
          </ImageModalContainer>
        </Modal>
      )}

      {showForm ? (
        <Form onSubmit={handleSubmit}>
          <Modal
            open={open}
            handleClose={handleModalClose}
            acceptButtonText="등록"
            handleAccept={onSubmitForm}
            title="매물 공유하기"
            titleAlign="center"
          >
            <InnerContainer>
              <SubTitle>물건 주소(필수)</SubTitle>
              <ReportSelect
                handleChange={handleReportChange}
                defaultValue={defaultReport}
                value={
                  saleValues && "address" in saleValues
                    ? { address: saleValues.address ?? "", id: saleValues.id }
                    : void 0
                }
                error={Boolean(errors.report_id)}
                helperText={errors.report_id}
              />
              <SubTitle>{values.trade_type === TradeType.Deal ? "가격(필수)" : "보증금(필수)"}</SubTitle>
              <PriceField
                type="number"
                inputProps={{ step: 10000, min: 0, style: { paddingRight: "5px", textAlign: "right" } }}
                {...getFieldProps("price")}
                onBlur={handleBlur}
                error={Boolean(errors.price)}
                helperText={errors.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TradeTypeSelect onChange={handleTypeChange} defaultValue={values.trade_type} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <MonetaryUnit>만원</MonetaryUnit>
                    </InputAdornment>
                  ),
                }}
              />
              {values.trade_type === TradeType.Rent && (
                <PriceField
                  type="number"
                  inputProps={{ step: 10000, min: 0, style: { paddingRight: "5px", textAlign: "right" } }}
                  {...getFieldProps("rent_price")}
                  onBlur={handleBlur}
                  error={Boolean(errors.rent_price)}
                  helperText={errors.rent_price}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RentAdornment>월세</RentAdornment>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <MonetaryUnit>만원</MonetaryUnit>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <TextField
                type="text"
                {...getFieldProps("title")}
                defaultValue={values.title ?? ""}
                placeholder="제목 (미기재시 주소명이 노출됩니다)"
              />
              <div>
                <StyledReactQuill
                  id="descriptions"
                  theme="snow"
                  placeholder="매물에 대해 소개하고 싶은 내용을 자유롭게 입력하세요. 물건의 주소, 타입, 층수는 자동으로 입력됩니다."
                  value={values.descriptions}
                  onChange={(content, delta, source, editor) => setFieldValue("descriptions", editor.getHTML())}
                />
                {errors.descriptions && <HelperText>{errors.descriptions}</HelperText>}
              </div>
              <ImageUploadContainer>
                <Button style={{ width: "100%" }} className="default" onClick={() => fileInputRef.current?.click()}>
                  <ButtonInnerContainer>
                    <FlexBox>
                      <PhotoCameraIcon />
                      이미지 첨부
                      <FileInput
                        ref={fileInputRef}
                        type="file"
                        onChange={handleImageSetChange}
                        multiple={true}
                        hidden
                      />
                    </FlexBox>
                  </ButtonInnerContainer>
                </Button>
                <ImageBoxContainer>
                  <ImagesBox>
                    {imageSet.length > 0 &&
                      imageSet.map((image, index) => (
                        <Image
                          src={Configs.DOCZIP_REST_API_URL + image.original_image_path}
                          key={index}
                          onClick={() => {
                            setSelectedImage(image);
                          }}
                        />
                      ))}
                  </ImagesBox>
                </ImageBoxContainer>
              </ImageUploadContainer>
            </InnerContainer>
          </Modal>
        </Form>
      ) : null}
    </>
  );
};

export default SaleNew;
