import React, { useCallback, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import * as yup from "yup";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CenteredLayout from "@/components/CenteredLayout";
import Layout from "@/components/Layout";
import Link from "@/components/Link";
import TextField, { TextFieldWithInnerButton } from "@/components/TextField";
import IamportLoader from "@/containers/IamportLoader";
import SignInFooter, { VerticalDivider } from "@/features/user/components/SignInFooter";
import { resetAuthState } from "@/redux/auth/actions";
import {
  getLoginFetchState,
  getSelfCertification,
  getSelfCertificationFetchState,
  getUserExists,
  getUserExistsFetchState,
} from "@/redux/auth/selectors";
import { fetchUserExistsThunk, loginThunk, selfCertificationThunk, signUpThunk } from "@/redux/auth/thunk";
import { openTextSnackbar } from "@/redux/snackbar/actions";
import { getUrl } from "@/utils/routes";
import { passwordSchema, phoneNumberSchema } from "@/utils/validator";

const NoticeText = styled.span`
  color: #ff4d4d;
  font-weight: 600;
`;

const FormContainer = styled.form`
  width: 100%;
`;

interface LoginProps {}

interface UserExistsCheckFormField {
  phoneNumber: string;
}

const userExistsCheckFormSchema = yup.object<UserExistsCheckFormField>({
  phoneNumber: phoneNumberSchema,
});

interface LoginFormField extends UserExistsCheckFormField {
  password: string;
}

const loginFormSchema = yup.object<LoginFormField>({
  phoneNumber: phoneNumberSchema,
  password: passwordSchema,
});

interface SignUpFormField extends LoginFormField {
  impUid: string;
}

const signUpFormSchema = yup.object<SignUpFormField>({
  phoneNumber: phoneNumberSchema,
  password: passwordSchema,
  impUid: yup.string().required("본인인증을 해 주세요"),
});

function LoginPage(props: LoginProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const search = parse(location.search);

  const userExists = useSelector(getUserExists);
  const userExistsFetchState = useSelector(getUserExistsFetchState);
  const loginFetchState = useSelector(getLoginFetchState);
  const selfCertification = useSelector(getSelfCertification);
  const selfCertificationFetchState = useSelector(getSelfCertificationFetchState);

  useEffect(() => {
    dispatch(resetAuthState());
  }, []);

  useEffect(() => {
    function handlePopstate() {
      if (typeof search.from === "string") {
        navigate(-1);
      }
    }

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (!userExists?.exists) {
      return;
    }

    dispatch(openTextSnackbar("가입된 번호입니다. 비밀번호를 입력해주세요."));
  }, [userExists]);

  useEffect(() => {
    if (loginFetchState !== "SUCCESS") {
      return;
    }

    navigate(getUrl("root"));
  }, [loginFetchState]);

  const checkUserExists = useCallback(
    (phoneNumber: string) => {
      if (userExistsFetchState === "FETCHING") {
        return;
      }

      dispatch(fetchUserExistsThunk(phoneNumber));
    },
    [userExistsFetchState],
  );

  const confirmButtonText = useMemo(() => {
    if (userExists === null) {
      return "다음단계";
    }

    if (userExists.exists) {
      return "로그인";
    }

    return "회원가입";
  }, [userExists]);

  const validationSchema = useMemo(() => {
    if (userExists === null || userExistsFetchState === "FETCHING") {
      return userExistsCheckFormSchema;
    }

    if (userExists.exists) {
      return loginFormSchema;
    }

    return signUpFormSchema;
  }, [userExists, userExistsFetchState]);

  const { handleSubmit, getFieldProps, errors, values, validateField, setFieldValue } = useFormik<SignUpFormField>({
    validationSchema,
    initialValues: {
      phoneNumber: "",
      password: "",
      impUid: "",
    },
    onSubmit: (formField) => {
      if (userExists === null) {
        checkUserExists(formField.phoneNumber);
        return;
      }

      if (!userExists.exists) {
        dispatch(signUpThunk(formField));
        return;
      }

      dispatch(loginThunk(formField));
    },
  });

  useEffect(() => {
    if (selfCertificationFetchState === "FETCHING") {
      return;
    }

    setFieldValue("impUid", selfCertification ?? "");
  }, [selfCertification, selfCertificationFetchState]);

  const handleSelfCertification = useCallback(() => {
    if (selfCertificationFetchState === "FETCHING") {
      return;
    }

    if (values.phoneNumber.length === 0) {
      validateField("phoneNumber");
      return;
    }

    dispatch(selfCertificationThunk(values.phoneNumber));
  }, [selfCertificationFetchState, values]);

  return (
    <CenteredLayout
      title={userExists?.exists === false ? "회원가입" : "로그인"}
      description={[
        "닥집에 오신 것을 환영합니다.",
        "권리관계 확인으로 다양한 부동산 정보를 확인해보세요!",
        userExists?.exists === false ? (
          <NoticeText>현재 닥집 무료 등기부등본 열람권 이벤트는 종료되었습니다.</NoticeText>
        ) : (
          <div />
        ),
      ]}
    >
      <FormContainer onSubmit={handleSubmit}>
        <TextFieldWithInnerButton
          fullWidth
          placeholder="휴대폰 번호"
          {...getFieldProps("phoneNumber")}
          helperText={errors.impUid ?? errors.phoneNumber}
          error={typeof (errors.impUid ?? errors.phoneNumber) !== "undefined"}
          hideInnerButton={userExists?.exists !== false}
          innerButtonChildren="본인인증"
          onInnerButtonClick={handleSelfCertification}
        />

        {userExists !== null && (
          <TextField
            fullWidth
            sx={{ mb: "36px" }}
            placeholder="비밀번호"
            {...getFieldProps("password")}
            helperText={errors.password}
            error={typeof errors.password !== "undefined"}
            type="password"
          />
        )}
        <PrimaryButton fullWidth type="submit">
          {confirmButtonText}
        </PrimaryButton>
      </FormContainer>
      <SignInFooter>
        <Link href={getUrl("signup")}>회원가입</Link>
        <VerticalDivider />
        <Link href={getUrl("resetPassword")}>비밀번호 찾기</Link>
      </SignInFooter>
    </CenteredLayout>
  );
}

interface LoginWithIamportLoaderProps {}

export default function LoginWithIamportLoader(props: LoginWithIamportLoaderProps) {
  return (
    <IamportLoader>
      <Layout columnless />
      <LoginPage />
    </IamportLoader>
  );
}
