import React from "react";
import styled from "styled-components";
import Layout from "@/components/Layout";
import DesktopLandingContainer from "@/features/landing/containers/DesktopLandingContainer";
import MobileLandingContainer from "@/features/landing/containers/MobileLandingContainer";
import useWindowSize from "@/hooks/useWindowSize";

const MobileHeaderContentContainer = styled.div``;

const MobileHeaderContent = styled.span`
  color: #323232;
  font-weight: 700;
  font-size: 16px;
`;

type Props = {};

const LandingContainer: React.FC<Props> = () => {
  const { width } = useWindowSize();

  return (
    <>
      <Layout
        columnless
        headerContent={
          width < 1190 && (
            <MobileHeaderContentContainer>
              <MobileHeaderContent>공부서류 간편발급 서비스, 닥집</MobileHeaderContent>
            </MobileHeaderContentContainer>
          )
        }
      />
      {width > 1190 ? <DesktopLandingContainer /> : <MobileLandingContainer />}
    </>
  );
};

export default LandingContainer;
