import React from "react";
import styled from "styled-components";
import FlatButton from "@/components/buttons/FlatButton";
import MobileInnerCommonLayout from "@/components/MobileInnerCommonLayout";
import TitledContainer from "@/components/TitledContainer";
import SaleModalOpenButton from "@/features/sales/components/SaleModalOpenButton";
import SaleSearch from "@/features/sales/components/SaleSearch";

const SaleTitledOuterContainer = styled.div`
  padding-bottom: "6px";
`;

const SaleTitledContainer = () => {
  return (
    <SaleTitledOuterContainer>
      <MobileInnerCommonLayout screenMaxWidth="864px">
        <TitledContainer
          title="매물 공유 게시판"
          actions={
            <>
              <SaleSearch />
              <SaleModalOpenButton as={<FlatButton className="outlined">매물 공유</FlatButton>} />
            </>
          }
        />
      </MobileInnerCommonLayout>
    </SaleTitledOuterContainer>
  );
};

export default SaleTitledContainer;
