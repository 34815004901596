import React, { useState } from "react";
import { Mode, RegisterAddressAddress, Status } from "@doczip/api-client";
import { addressApi } from "@/client";
import DocumentAddressInput from "@/features/documents/components/DocumentAddressInput";

type Props = {
  inputValue: string;
  onInputChange: (inputValue: string) => void;
  value?: RegisterAddressAddress;
  onChange: (address: RegisterAddressAddress) => void;
};

const DocumentAddressInputContainer: React.FC<Props> = (props) => {
  const [addresses, setAddresses] = useState<RegisterAddressAddress[]>();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);

  const handleSearch = async () => {
    setBusy(true);
    try {
      const response = await addressApi.getRegisterAddress(props.inputValue, Mode.All, Status.Present);
      setAddresses(response.data.addresses);
    } catch (err) {
      setAddresses([]);
      setError(true);
    } finally {
      setBusy(false);
    }
  };

  const handleChange = (address: RegisterAddressAddress) => {
    setAddresses([address]);
    props.onChange(address);
  };

  return (
    <DocumentAddressInput
      busy={busy}
      error={error}
      addresses={addresses || []}
      onSearch={handleSearch}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      value={props.value}
      onChange={handleChange}
    />
  );
};

export default DocumentAddressInputContainer;
