/* eslint-disable react/no-danger */
/* Forcefully set innerHTML contents can be written only by admin users. */
import React, { useEffect, useState } from "react";
import { PostLimitedResponse, RegisterAddressAddress, ReportLogResponse } from "@doczip/api-client";
import { Close as CloseIcon } from "@mui/icons-material";
import { FormControlLabel, IconButton, Radio, RadioGroup, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DocumentLoadingDialog from "./DocumentLoadingDialog";
import LoadingCircle from "@/components/LoadingCircle";
import NotFound from "@/components/NotFound";
import StyledButton from "@/components/StyledButton";
import DocumentAddressInputContainer from "@/features/documents/containers/DocumentAddressInputContainer";
import { colors, lengths } from "@/styles/values";
import { toYmd } from "@/utils/datetime";
import { alert, confirm } from "@/utils/dialog";
import { getRegisteredTypeString } from "@/utils/enum";
import { getUrl } from "@/utils/routes";
import { amountToKorean } from "@/utils/text";

const DocumentNewBox = styled.div`
  width: 100%;
  max-width: ${lengths.narrowContainerWidth};
  margin: 0 auto;
  padding: 40px 0;
`;

const DocumentAlertBox = styled.a`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 60px;
  margin-bottom: 20px;
  padding: 10px 37px 10px 48px;

  color: ${colors.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;

  background-color: ${colors.dark};
  cursor: pointer;
`;

const DocumentAlertIcon = styled.div`
  position: absolute;
  top: 21.43px;
  left: 19.42px;

  width: 16.17px;
  height: 17.14px;

  background-image: url("/images/speaker.svg");
  background-size: 100% 100%;
`;

const DocumentFormBox = styled.div`
  padding: 22px 20px;

  background-color: ${colors.white};
`;

const LabelRow = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 27px;
  padding-left: 28px;

  color: ${colors.black};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const LabelIcon = styled.div`
  position: absolute;
  top: 3px;
  left: 0;

  width: 20px;
  height: 20px;

  background-image: url("/images/pin.png");
  background-size: 100% 100%;
`;

const RadioGroupRow = styled.div`
  margin-bottom: 30px;
  padding-left: 19px;

  & .MuiFormControlLabel-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

const AmountInputBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 13px;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-top: 13px;

  & .MuiInputBase-root {
    height: 48px;

    border-radius: 0;
    outline: none;
  }

  & .MuiInputBase-input {
    height: 48px;

    border-radius: 0;
  }
`;

const AddressInputBox = styled.div`
  padding-top: 13px;
`;

const AddressBox = styled.div`
  position: relative;

  padding: 12px 18px;

  color: ${colors.black};
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.5px;

  border: 1px solid #e3e3e3;

  &.active {
    font-weight: bold;

    background-color: #e3e3e3;
  }
`;

const AddressBoxDeleteButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const ConfirmButtonBox = styled.div`
  padding-top: 24px;
`;

const Red = styled.span`
  color: ${colors.red};
`;

type Props = {
  busy?: boolean;
  error?: boolean;
  createError?: boolean;
  clearCreateError: () => void;
  manual?: boolean;
  reportLogId?: number;
  reportLog?: ReportLogResponse;
  posts: PostLimitedResponse[];
  tradeType: string;
  onTradeTypeChange: (v: string) => void;
  reportType: string;
  onReportTypeChange: (v: string) => void;
  amount?: number;
  onAmountChange: (v: number) => void;
  rent?: number;
  onRentChange: (v: number) => void;
  address?: RegisterAddressAddress;
  onAddressChange: (v: RegisterAddressAddress) => void;
  onCreate: () => void;
};

const DocumentNew: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [addressInput, setAddressInput] = useState("");
  const radioStyle = { "&.Mui-checked": { color: colors.main } };
  const documentName = props.manual ? "확인설명서" : "계약서";

  const handleCreateError = async () => {
    await alert("문서 생성에 실패했습니다.");
    props.clearCreateError();
  };

  useEffect(() => {
    if (props.createError) {
      handleCreateError();
    }
  }, [props.createError]);

  if (props.error) {
    return <NotFound />;
  }

  const handleDeleteReport = async () => {
    if (await confirm("자동완성에 사용할 공부를 선택하기 위해 내 열람 내역 페이지로 이동하시겠습니까?")) {
      navigate(getUrl("reportsRoot"));
    }
  };

  const postsContent =
    props.posts.length > 0 &&
    props.posts.map((post) => (
      <DocumentAlertBox key={post.id} as={Link} to={getUrl("postsDetail", { params: { postId: post.id } })}>
        <DocumentAlertIcon />
        <div>
          <b>{post.title}</b>
          <br />
          <span dangerouslySetInnerHTML={{ __html: post.summery }} />
        </div>
      </DocumentAlertBox>
    ));

  const reportContent = props.reportLog ? (
    <AddressBox>
      <AddressBoxDeleteButton>
        <IconButton aria-label="delete" onClick={handleDeleteReport}>
          <CloseIcon />
        </IconButton>
      </AddressBoxDeleteButton>
      {getRegisteredTypeString(props.reportLog.registered_type)}
      <br />
      {props.reportLog.registered_address}
      <br />
      열람일: {toYmd(props.reportLog.created_datetime, { useIsoFormat: true })}
    </AddressBox>
  ) : (
    <LoadingCircle />
  );

  const invalid = (props.reportType === "new" && !props.address) || (props.reportType === "reuse" && !props.reportLog);

  return (
    <>
      {props.busy && <DocumentLoadingDialog />}
      <DocumentNewBox>
        {postsContent}
        <DocumentFormBox>
          <LabelRow>
            <LabelIcon />
            {documentName} 자동완성 방법을 선택해주세요.
          </LabelRow>
          <RadioGroupRow>
            <RadioGroup row value={props.reportType} onChange={(e) => props.onReportTypeChange(e.target.value)}>
              <FormControlLabel
                value="reuse"
                control={<Radio size="small" sx={radioStyle} />}
                label="이전에 열람한 공부 사용"
              />
              <FormControlLabel
                value="new"
                control={<Radio size="small" sx={radioStyle} />}
                label={
                  <span>
                    공부 새로 열람 <Red>(다운로드권 차감)</Red>
                  </span>
                }
              />
              <FormControlLabel
                value="blank"
                control={<Radio size="small" sx={radioStyle} />}
                label="자동완성 하지 않음 (빈칸 템플릿 사용)"
              />
            </RadioGroup>
          </RadioGroupRow>
          <LabelRow>
            <LabelIcon />
            계약의 종류를 선택해주세요.
          </LabelRow>
          <RadioGroupRow>
            <RadioGroup row value={props.tradeType} onChange={(e) => props.onTradeTypeChange(e.target.value)}>
              <FormControlLabel value="trade" control={<Radio size="small" sx={radioStyle} />} label="매매" />
              <FormControlLabel value="jeonse" control={<Radio size="small" sx={radioStyle} />} label="전세" />
              <FormControlLabel value="rent" control={<Radio size="small" sx={radioStyle} />} label="월세" />
            </RadioGroup>
          </RadioGroupRow>
          <LabelRow>
            <LabelIcon />
            거래 금액을 입력해주세요.
          </LabelRow>
          <AmountInputBox>
            <TextField
              type="number"
              value={props.amount ? props.amount.toString() : ""}
              helperText={props.amount ? `${amountToKorean(props.amount)}원` : ""}
              onChange={(e) => props.onAmountChange(parseInt(e.target.value, 10))}
              placeholder={props.tradeType === "trade" ? "매매 금액 입력" : "보증금 입력"}
              inputProps={{ step: 10000 }}
              fullWidth
            />
            {props.tradeType === "rent" && (
              <TextField
                type="number"
                value={props.rent ? props.rent.toString() : ""}
                helperText={props.rent ? `${amountToKorean(props.rent)}원` : ""}
                onChange={(e) => props.onRentChange(parseInt(e.target.value, 10))}
                placeholder="월세 입력"
                inputProps={{ step: 10000 }}
                fullWidth
              />
            )}
          </AmountInputBox>
          <LabelRow>
            <LabelIcon />
            대상 물건의 주소를 입력해주세요.
          </LabelRow>
          <AddressInputBox>
            {props.reportType === "reuse" ? (
              reportContent
            ) : (
              <DocumentAddressInputContainer
                inputValue={addressInput}
                onInputChange={(v) => setAddressInput(v)}
                value={props.address}
                onChange={(v) => props.onAddressChange(v)}
              />
            )}
          </AddressInputBox>
          <ConfirmButtonBox>
            <StyledButton bold fullWidth onClick={() => props.onCreate()} disabled={invalid}>
              문서 작성하기
            </StyledButton>
          </ConfirmButtonBox>
        </DocumentFormBox>
      </DocumentNewBox>
    </>
  );
};

export default DocumentNew;
