import React from "react";
import PostLayout from "@/features/posts/components/common/PostLayout";
import PostTypeSelect from "@/features/posts/components/PostTypeSelect";
import PostShowContainer from "@/features/posts/containers/PostShowContainer";
import PostTitledContainer from "@/features/posts/containers/PostTitledContainer";

const PostShowPage = () => {
  return (
    <PostLayout>
      <PostTitledContainer />
      <PostTypeSelect />
      <PostShowContainer />
    </PostLayout>
  );
};

export default PostShowPage;
