import React from "react";
import CardButtonContainer from "@/components/CardButtonContainer";
import CenteredLayout from "@/components/CenteredLayout";
import TitledContainer from "@/components/TitledContainer";
import ChangePassword from "@/features/user/components/userEdit/ChangePassword";
import UserEditLayout from "@/features/user/components/UserEditLayout";
import UserChangePhoneNumberContainer from "@/features/user/containers/UserChangePhoneNumberContainer";
import UserDetailEditContainer from "@/features/user/containers/UserDetailEditContainer";
import UserVerifyButtonsContainer from "@/features/user/containers/UserVerifyButtonsContainer";

interface UserEditContainerProps {}

export default function UserEditContainer(props: UserEditContainerProps) {
  return (
    <CenteredLayout title="개인정보 수정">
      <UserEditLayout>
        <TitledContainer title="추가인증">
          <CardButtonContainer>
            <UserVerifyButtonsContainer />
          </CardButtonContainer>
        </TitledContainer>
        <UserChangePhoneNumberContainer />
        <ChangePassword />
        <UserDetailEditContainer />
      </UserEditLayout>
    </CenteredLayout>
  );
}
