import React, { useState } from "react";
import styled from "styled-components";
import { SelectProps } from "@/features/posts/components/common/Select";
import { colors, lengths } from "@/styles/values";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 20px;

  @media screen and (max-width: ${lengths.desktop}) {
    display: block;
    padding-bottom: 0;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex: 0;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
  height: 40px;

  color: #797979;
  font-weight: 500;
  font-size: 13px;

  border-top: 1px solid #dfe2e6;
  border-right: 0;
  border-bottom: 1px solid ${colors.black};
  border-left: 1px solid #dfe2e6;

  &:last-of-type {
    border-right: 1px solid #dfe2e6;
  }

  &.selected {
    color: ${colors.black};
    font-weight: 700;

    border: 1px solid ${colors.black};
    border-bottom: 0;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    flex: 1;

    border-top: none;

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  cursor: pointer;
`;

const BottomLine = styled.div`
  z-index: 1;

  width: 100%;

  border-bottom: 1px solid ${colors.black};

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const BorderSelect = <T,>({ options, defaultOption }: SelectProps<T>) => {
  const [selected, change] = useState(defaultOption);

  return (
    <Container>
      <ItemsContainer>
        {options.map((option, index) => (
          <Item
            key={index}
            className={selected && option.title === selected.title ? "selected" : ""}
            onClick={() => {
              change(option);
              option.handleClick(option.key);
            }}
          >
            {option.title}
          </Item>
        ))}
      </ItemsContainer>
      <BottomLine />
    </Container>
  );
};

export default BorderSelect;
