import React, { useEffect, useState } from "react";
import { DocumentListResponse } from "@doczip/api-client";
import { documentApi } from "@/client";
import CommonLayout from "@/components/CommonLayout";
import MobileInnerCommonLayout from "@/components/MobileInnerCommonLayout";
import TitledContainer from "@/components/TitledContainer";
import DocumentList from "@/features/documents/components/DocumentList";
import { alert } from "@/utils/dialog";

const pageSize = 20;

type Props = {};

const DocumentListContainer: React.FC<Props> = () => {
  const [documents, setDocuments] = useState<DocumentListResponse[]>();
  const [deletingId, setDeletingId] = useState<number>();
  const [more, setMore] = useState(true);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);

  const fetchDocuments = async (refresh?: boolean) => {
    if (busy) {
      return;
    }
    setBusy(true);
    setError(false);
    try {
      if (refresh) {
        const response = await documentApi.getDocuments(0, pageSize, "me");
        setDocuments(response.data);
        setMore(response.data.length >= pageSize);
      } else {
        const response = await documentApi.getDocuments(documents?.length || 0, pageSize, "me");
        setDocuments([...(documents || []), ...response.data]);
        setMore(response.data.length >= pageSize);
      }
    } catch (err) {
      setError(true);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleDelete = async (documentId: number) => {
    setDeletingId(documentId);
    try {
      await documentApi.deleteDocument(documentId);
    } catch (err) {
      await alert("문서를 삭제하는데 실패했습니다.");
    } finally {
      setDeletingId(void 0);
    }
  };

  return (
    <CommonLayout>
      <MobileInnerCommonLayout>
        <TitledContainer title="내 문서함" />
      </MobileInnerCommonLayout>
      <DocumentList
        busy={busy}
        error={error}
        more={more}
        deletingId={deletingId}
        documents={documents || []}
        onLoadMore={fetchDocuments}
        onDelete={handleDelete}
      />
    </CommonLayout>
  );
};

export default DocumentListContainer;
