import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import AmountText from "@/components/AmountText";
import CardButton, { CardPrimaryButton } from "@/components/buttons/CardButton";
import Card from "@/components/Card";
import CardButtonContainer from "@/components/CardButtonContainer";
import DownloadTicketCard from "@/components/DownloadTicketCard";
import FlexContainer from "@/components/FlexContainer";
import Link from "@/components/Link";
import TitledContainer from "@/components/TitledContainer";
import { ReportLogList } from "@/features/reports/components/ReportLog";
import UserOwnersClubCard from "@/features/user/components/UserOwnersClubCard";
import UserShowLayout from "@/features/user/components/UserShowLayout";
import { loadLoginState } from "@/redux/auth/functions";
import { resetReportLogListState, resetReportLogTrashListState, resetReportActionState } from "@/redux/logs/actions";
import { getDeleteReportLogFetchState, getReportLogListState } from "@/redux/logs/selectors";
import { fetchMoreReportLogListThunk } from "@/redux/logs/thunk";
import { getUserMe } from "@/redux/user/selectors";
import { getUrl } from "@/utils/routes";

interface UserShowPageProps {}

export default function UserShowPage(props: UserShowPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMe = useSelector(getUserMe);
  const isLoggedIn = loadLoginState() === "SUCCESS";
  const [searchParams] = useSearchParams();
  const subscribeModalParams = searchParams.get("subscribe_modal");
  const {
    value: reportLogList,
    fetchState: reportLogListFetchState,
    page: reportLogListPage,
    totalPage: reportLogListTotalPage,
  } = useSelector(getReportLogListState);
  const deleteReportLogFetchState = useSelector(getDeleteReportLogFetchState);

  const handleResetAndLoadReportLogList = () => {
    dispatch(resetReportLogTrashListState());
    dispatch(resetReportActionState());

    if (reportLogListFetchState === "FETCHING") {
      return;
    }

    dispatch(resetReportLogListState());
    dispatch(fetchMoreReportLogListThunk({ deleted: false }));
  };

  useEffect(() => {
    handleResetAndLoadReportLogList();
  }, []);

  useEffect(() => {
    if (deleteReportLogFetchState !== "SUCCESS") {
      return;
    }

    handleResetAndLoadReportLogList();
  }, [deleteReportLogFetchState]);

  const verifyCompleted = useMemo(() => {
    if (!userMe) {
      return false;
    }

    return userMe.identity_verified && userMe.realtor_verified;
  }, [userMe]);

  const initialModalOpen = useMemo(() => {
    if (!subscribeModalParams) {
      return;
    }

    return subscribeModalParams === "open";
  }, [subscribeModalParams]);

  const handleVerify = useCallback(() => {
    if (!userMe || verifyCompleted) {
      return;
    }

    if (userMe.identity_verified) {
      navigate(getUrl("userVerifyRealtor"));
      return;
    }

    navigate(getUrl("userVerifyIdentity"));
  }, [userMe, verifyCompleted]);

  if (!isLoggedIn) {
    return <Navigate to={getUrl("login")} />;
  }

  return (
    <UserShowLayout>
      {userMe && (
        <>
          <TitledContainer title="내 계정">
            <FlexContainer transationWidth="1560px">
              <Card
                title={userMe.username}
                subTitle={userMe.detail?.phone_number}
                actions={
                  <CardButtonContainer>
                    <CardPrimaryButton onClick={handleVerify} disabled={verifyCompleted}>
                      추가인증{verifyCompleted && "(완료)"}
                    </CardPrimaryButton>
                    <Link href={getUrl("userEdit")}>
                      <CardButton>수정</CardButton>
                    </Link>
                  </CardButtonContainer>
                }
              />
              <DownloadTicketCard hideLeadingIcon />
              <Card
                title="작성한 문서"
                subTitle={<AmountText>{userMe.document_count}개</AmountText>}
                actions={
                  <CardButtonContainer>
                    <Link href={getUrl("documentsRoot")}>
                      <CardButton>모두보기</CardButton>
                    </Link>
                  </CardButtonContainer>
                }
              />
              <UserOwnersClubCard initialModalOpen={initialModalOpen} />
            </FlexContainer>
          </TitledContainer>
          <TitledContainer title="최근 열람 내역">
            <ReportLogList
              list={reportLogList}
              fetchState={reportLogListFetchState}
              page={reportLogListPage}
              totalPage={reportLogListTotalPage}
              showRecentOnly
              hideShowAllButton={false}
            />
          </TitledContainer>
        </>
      )}
    </UserShowLayout>
  );
}
