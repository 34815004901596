import React from "react";
import { PostRequest, PostResponse } from "@doczip/api-client";
import { useDispatch } from "react-redux";
import PostNew from "@/features/posts/components/PostNew";
import { createPostThunk, updatePostThunk } from "@/redux/posts/thunk";

type PostNewModalProps = {
  open: boolean;
  handleClose: () => void;
  post?: PostResponse | null;
};

const PostNewModal = ({ open, handleClose, post }: PostNewModalProps) => {
  const dispatch = useDispatch();

  const savePost = async (postRequest: PostRequest) => {
    if (post) {
      dispatch(updatePostThunk({ id: post.id, postRequest }));
    } else {
      dispatch(createPostThunk(postRequest));
    }
  };

  return <PostNew savePost={savePost} post={post} open={open} handleClose={handleClose} />;
};

export default PostNewModal;
