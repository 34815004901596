import React from "react";
import SaleLayout from "@/features/sales/components/common/SaleLayout";
import SaleListContainer from "@/features/sales/containers/SaleListContainer";
import SaleTitledContainer from "@/features/sales/containers/SaleTitledContainer";

const SaleListPage = () => {
  return (
    <SaleLayout>
      <SaleTitledContainer />
      <SaleListContainer />
    </SaleLayout>
  );
};

export default SaleListPage;
