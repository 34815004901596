import React from "react";
import { ButtonBase, Modal as MuiModal } from "@mui/material";
import styled from "styled-components";
import { colors, lengths } from "@/styles/values";

const StyledModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  min-width: 343px;
  max-height: 95vh;
  padding: 20px 20px;
  overflow: auto;

  background-color: ${colors.white};

  @media screen and (max-width: ${lengths.desktop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

interface AlignProps {
  align?: string;
}

const TitleContainer = styled.div<AlignProps>`
  margin: 27px 0 24px;

  text-align: ${({ align }) => align};

  @media screen and (max-width: ${lengths.desktop}) {
    margin: 22px 0 14px;
  }
`;

const Title = styled.span`
  color: #000;
  font-weight: 700;
  font-size: 16px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 14px;
  }
`;

const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 42px;
  }

  &.cancel {
    &.MuiButtonBase-root {
      color: rgba(21, 25, 32, 0.5);
      font-weight: 600;
      font-size: 14px;

      border: 1px solid #56678942;
    }
  }

  &.accept {
    &.MuiButtonBase-root {
      color: #fff;
      font-weight: 600;
      font-size: 14px;

      background-color: #ff8f17;
      border: none;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;

  @media screen and (max-width: ${lengths.desktop}) {
  }
`;

type ModalProps = {
  open: boolean;
  handleClose?: () => void;
  handleAccept?: () => void;
  title?: string;
  titleAlign?: React.CSSProperties["textAlign"];
  children?: React.ReactNode;
  cancelButtonText?: string;
  acceptButtonText?: string;
  hideCancelButton?: boolean;
  withoutDefaultStyle?: boolean;
  withoutButtons?: boolean;
};

const Modal = ({
  open,
  handleClose,
  handleAccept,
  title,
  titleAlign = "left",
  children,
  cancelButtonText = "닫기",
  acceptButtonText = "확인",
  hideCancelButton = false,
  withoutDefaultStyle = false,
  withoutButtons = false,
}: ModalProps) => {
  return (
    <StyledModal open={open} onClose={handleClose}>
      {!withoutDefaultStyle ? (
        <Container>
          {title && (
            <TitleContainer align={titleAlign}>
              <Title>{title}</Title>
            </TitleContainer>
          )}
          {children && children}
          {!withoutButtons && (
            <ButtonContainer>
              {!hideCancelButton && (
                <Button className="cancel" onClick={handleClose}>
                  {cancelButtonText}
                </Button>
              )}
              <Button className="accept" onClick={handleAccept}>
                {acceptButtonText}
              </Button>
            </ButtonContainer>
          )}
        </Container>
      ) : (
        <>{children}</>
      )}
    </StyledModal>
  );
};

export default Modal;
