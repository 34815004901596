import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Checkbox, { CheckboxFormControlLabel } from "@/components/Checkbox";
import Modal from "@/components/Modal";
import useUserData from "@/features/user/hooks/useUserData";
import { colors, lengths } from "@/styles/values";
import { getItemWithExpiry, setItemWithExpiry } from "@/utils/localStorage";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  position: relative;
`;

const CancelButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  color: ${colors.gray};

  cursor: pointer;
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 470px;

  @media screen and (max-width: ${lengths.desktop}) {
    max-width: 375px;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0 0 10px;

  font-size: 16px;
  line-height: 27px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 13px;
  }
`;

const Title = styled.h1`
  padding-bottom: 5px;

  font-weight: 700;
  font-size: 20px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 16px;
  }
`;

interface OwnersClubModalProps {}

const OwnersClubModal: React.FC<OwnersClubModalProps> = () => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const { isTanker } = useUserData();

  const fakeHandleClose = () => {};

  const handleClose = () => {
    if (checked) {
      setItemWithExpiry("openModal", "false");
    }
    setOpen(false);
  };

  const handleAccept = () => {
    navigate(getUrl("userRoot", { query: { subscribe_modal: "open" } }));
  };

  useEffect(() => {
    const openModal = getItemWithExpiry("openModal");
    if (!openModal && isTanker) {
      setOpen(true);
    }
  }, []);

  return (
    <Modal
      open={open}
      handleClose={fakeHandleClose}
      handleAccept={handleAccept}
      hideCancelButton
      acceptButtonText="무료로 오너스클럽 업그레이드하기"
    >
      <Container>
        <CancelButtonContainer onClick={handleClose}>
          <Close />
        </CancelButtonContainer>
        <TextContainer>
          <Title>🎉 오너스클럽 업그레이드 이벤트 🎉</Title>
          <Text>
            지금 오너스클럽 결제수단을 업데이트하시면 추가비용 없이 열람권 5건(3900원 상당)을 무료로 지급해드립니다.
            업그레이드 시 2월 내에 추가될 법인 등기 발급도 무료로 사용하실 수 있습니다!
          </Text>
          <Text>
            또한 닥집 운영회사 변경 등으로 인해 빠른 시일내 오너스클럽 업그레이드를 진행하지 않으신다면 정기결제만
            진행되고 이용권이 정상적으로 적용되지 않으실 수 있습니다.
          </Text>
          <CheckboxFormControlLabel
            style={{ marginTop: "10px", marginBottom: "12px" }}
            control={<Checkbox sx={{ marginLeft: "-6px", marginTop: "-10px", marginBottom: "-10px" }} />}
            label="오늘 하루 팝업 보지않기."
            checked={checked}
            onChange={() => setChecked((prev) => !prev)}
          />
        </TextContainer>
      </Container>
    </Modal>
  );
};

export default OwnersClubModal;
