import React, { useCallback, useMemo } from "react";
import { ReportLogUserRelationResponse } from "@doczip/api-client";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LandingTempRegisterLedgerImage from "@/features/landing/assets/landing-temp-register-ledger.png";
import { getUrl } from "@/utils/routes";

const RegisterLedgerCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 70%;

  background-color: #f3f4f6;
`;

const ThumbnailContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Thumbnail = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 80%;
  height: 70%;

  background-image: url(${LandingTempRegisterLedgerImage});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  transform: translateX(-50%);
`;

const StatusContainer = styled.div`
  position: absolute;
  top: 18px;
  left: 20px;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 35px;

  background-color: #ff8f17;
  border-radius: 4px;
`;

const Status = styled.span`
  color: #fff;
  font-weight: 700;
  font-size: 11px;
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30%;
  padding: 0 19px;
`;

const Address = styled.span`
  color: #081a51;
  font-weight: 400;
  font-size: 13px;
`;

interface RegisterLedgerCardProps {
  report: ReportLogUserRelationResponse | null;
}

const RegisterLedgerCard: React.FC<RegisterLedgerCardProps> = ({ report }) => {
  const navigate = useNavigate();

  const address = useMemo(() => {
    if (!report || !report?.report_log || !report?.report_log?.registered_address) {
      return;
    }

    return report.report_log.registered_address;
  }, [report]);

  const reportLogId = useMemo(() => {
    if (!report || !report?.report_log || !report?.report_log?.id) {
      return;
    }

    return report.report_log.id;
  }, [report]);

  const handleCreateReport = useCallback(() => {
    if (reportLogId) {
      navigate(getUrl("reportsDetail", { params: { reportId: reportLogId }, query: { type: "normal" } }));
    }
  }, [reportLogId]);

  return (
    <RegisterLedgerCardContainer onClick={handleCreateReport}>
      <ImageContainer>
        <StatusContainer>
          <Status>새발급</Status>
        </StatusContainer>

        <ThumbnailContainer>
          <Thumbnail />
        </ThumbnailContainer>
      </ImageContainer>

      <AddressContainer>
        <Address>{address}</Address>
      </AddressContainer>
    </RegisterLedgerCardContainer>
  );
};

export default RegisterLedgerCard;
