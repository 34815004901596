import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChangeNotification } from "api/dist";
import StyledButton from "@/components/StyledButton";
import { lengths } from "@/styles/values";
import { format } from "@/utils/datetime";

const ReminderItem = styled.div`
  padding: 16px 20px;

  border-bottom: 1px solid #eeeff1;

  &:last-child {
    border-bottom: none;
  }
`;

interface ReminderReasonProps {
  reason?: number;
}

const ReminderReason = styled.div<ReminderReasonProps>`
  display: inline-block;
  margin-bottom: 6px;
  padding: 4px 8px;

  color: ${({ reason }) => {
    switch (reason) {
      case 1:
        return "#7b5c1f";
      case 2:
        return "#c14141";
      default:
        return "#7b5c1f";
    }
  }};
  font-weight: 500;
  font-size: 12px;

  background-color: ${({ reason }) => {
    switch (reason) {
      case 1:
        return "#fff6c5";
      case 2:
        return "#ffece5";
      default:
        return "#fff6c5";
    }
  }};
  border-radius: 6px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 11px;
  }
`;

const ReminderInnerRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReminderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ReminderTitle = styled.h3`
  margin: 0;

  color: #333;
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 13px;
  }
`;

const ReminderDate = styled.span`
  margin: 0;

  color: #718096;
  font-weight: 400;
  font-size: 12px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 11px;
  }
`;

const RowItemRightArea = styled.div`
  padding-left: 12px;
`;

const ButtonBox = styled.div`
  @media screen and (max-width: ${lengths.desktop}) {
    min-width: 51.5px;
  }
`;

type ReportReminderItemProps = {
  report: ChangeNotification;
};

const ReportReminderItem = ({ report }: ReportReminderItemProps) => {
  const [buttonText, setButtonText] = useState<string>("Click me");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1190) {
        setButtonText("재열람");
      } else {
        setButtonText("등기부 재열람 하기");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ReminderItem>
      <ReminderReason reason={2}>{report.purpose}</ReminderReason>
      <ReminderInnerRow>
        <ReminderInfo>
          <ReminderTitle>{report.address}</ReminderTitle>
          <ReminderDate>{format(report.created_datetime, "yyyy-MM-dd a h:mm")}</ReminderDate>
        </ReminderInfo>
        <RowItemRightArea>
          <ButtonBox>
            <StyledButton bold outlined height="36px" fontSize="12px" fullWidth>
              {buttonText}
            </StyledButton>
          </ButtonBox>
        </RowItemRightArea>
      </ReminderInnerRow>
    </ReminderItem>
  );
};

export default ReportReminderItem;
