import React, { useEffect, useRef, useState } from "react";
import { SaleCommentReplyResponse, SaleCommentResponse, UserResponse } from "@doczip/api-client";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FeedbackIcon from "@mui/icons-material/Feedback";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "styled-components";
import Dropdown from "@/components/dropdown";
import SaleCommentInput from "@/features/sales/components/SaleCommentInput";
import { BasicCommentProps, SaveCommentProps, SaveCommentReactionProps } from "@/features/sales/components/type";
import { confirm } from "@/utils/dialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Username = styled.span`
  color: #797979;
  font-weight: 700;
  font-size: 12px;

  &.me {
    padding: 3px 5px;

    color: white;

    background-color: #888887;
    border-radius: 10px;
  }
`;

const Content = styled.span`
  color: #718096;
  font-weight: 400;
  font-size: 13px;
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
`;

const MenuButton = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  color: #797979;
  font-size: 12px;

  background-color: inherit;
  border: none;
  cursor: pointer;
`;

const Replies = styled(MenuButton)``;

// type ClickableButtonProps = {
//   clicked?: boolean;
// };

const Like = styled(MenuButton)``;

const MoreButton = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  padding: 9px 12px;

  color: #797979;
  font-size: 16px;

  background-color: inherit;
  border: none;
  cursor: pointer;
`;

const SaleCommentInputContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 26px;
`;

const ModalContainer = styled.div`
  min-width: 256px;
  padding: 10px 16px;

  background-color: white;
  border-radius: 8px;
`;

type SaleCommentProps = {
  user: UserResponse | null;
  comment: SaleCommentResponse | SaleCommentReplyResponse;
  onSaveComment: (props: SaveCommentProps) => void;
  onDeleteComment: (props: BasicCommentProps) => void;
  onCommentReaction: (props: SaveCommentReactionProps) => void;
};

const SaleComment = ({ user, comment, onSaveComment, onDeleteComment, onCommentReaction }: SaleCommentProps) => {
  const contentRef = useRef<HTMLSpanElement | null>(null);
  const [editable, setEditable] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const isMeInLikeReactionUsers =
    user && comment.like_reaction_users.findIndex((reationUser) => reationUser.user.id === user.id) !== -1;

  useEffect(() => {
    if (editable) {
      if (contentRef && contentRef.current) {
        contentRef.current.focus();
      }
    }
  }, [editable]);

  const handleEdit = async () => {
    setEditable(true);
  };

  const handleDelete = async () => {
    if (await confirm("정말로 삭제하시겠습니까?")) {
      if ("replies" in comment) {
        onDeleteComment({ commentType: "COMMENT", id: comment.id });
      } else {
        onDeleteComment({ commentType: "REPLY", id: comment.id });
      }
    }
  };

  const handleReport = async () => {
    if ("replies" in comment) {
      onCommentReaction({ commentType: "COMMENT", id: comment.id, reactionType: "REPORT" });
    } else {
      onCommentReaction({ commentType: "REPLY", id: comment.id, reactionType: "REPORT" });
    }
  };

  const handleLike = async () => {
    if ("replies" in comment) {
      onCommentReaction({
        commentType: "COMMENT",
        id: comment.id,
        reactionType: "LIKE",
        isDelete: isMeInLikeReactionUsers ?? false,
      });
    } else {
      onCommentReaction({
        commentType: "REPLY",
        id: comment.id,
        reactionType: "LIKE",
        isDelete: isMeInLikeReactionUsers ?? false,
      });
    }
  };

  const username = comment.user.nickname || "일반유저(" + comment.user.username + ")";

  return (
    <>
      {!editable ? (
        <Container>
          <Header>
            <Username className={user && user.id === comment.user.id ? "me" : ""}>{username}</Username>
            {user && (
              <Dropdown>
                <Dropdown.Trigger
                  as={
                    <MenuButton>
                      <MoreVertIcon sx={{ fontSize: "14px" }} />
                    </MenuButton>
                  }
                />
                <Dropdown.Modal>
                  {user.id === comment.user.id ? (
                    <ModalContainer>
                      <MoreButton onClick={handleEdit}>
                        <EditIcon sx={{ fontSize: "18px" }} />
                        수정하기
                      </MoreButton>
                      <Dropdown.MenuItem onClick={handleDelete}>
                        <MoreButton>
                          <DeleteIcon sx={{ fontSize: "18px" }} />
                          삭제하기
                        </MoreButton>
                      </Dropdown.MenuItem>
                    </ModalContainer>
                  ) : (
                    <ModalContainer>
                      <MoreButton onClick={handleReport}>
                        <FeedbackIcon sx={{ fontSize: "18px" }} />
                        신고하기
                      </MoreButton>
                    </ModalContainer>
                  )}
                </Dropdown.Modal>
              </Dropdown>
            )}
          </Header>
          <Content ref={contentRef} contentEditable={editable}>
            {comment.safe_content}
          </Content>
          <Footer>
            <Like onClick={handleLike}>
              {isMeInLikeReactionUsers ? (
                <FavoriteIcon sx={{ fontSize: "14px" }} />
              ) : (
                <FavoriteBorderIcon sx={{ fontSize: "14px" }} />
              )}
              {comment.like_reaction_users.length > 0 ? comment.like_reaction_users.length : "좋아요"}
            </Like>
            <Replies onClick={() => setShowInput((prev) => !prev)}>
              <ChatBubbleOutlineIcon sx={{ fontSize: "14px" }} />
              답글 달기
            </Replies>
          </Footer>
          {showInput && (
            <SaleCommentInputContainer>
              <SaleCommentInput
                id={"replies" in comment ? comment.id : comment.sale_comment_id}
                user={user}
                placeholder={username + "에게 답글 달기"}
                onSaveComment={onSaveComment}
                reply
              />
            </SaleCommentInputContainer>
          )}
        </Container>
      ) : (
        <SaleCommentInput
          id={comment.id}
          user={user}
          defaultValue={comment.safe_content}
          placeholder="댓글 수정하기..."
          onSaveComment={onSaveComment}
          reply={!("replies" in comment)}
        />
      )}
    </>
  );
};

export default SaleComment;
