import React from "react";
import { SaleCommentResponse, UserResponse } from "@doczip/api-client";
import styled from "styled-components";
import SaleCommentInput from "@/features/sales/components/SaleCommentInput";
import SaleCommentItem from "@/features/sales/components/SaleCommentItem";
import { BasicCommentProps, SaveCommentProps, SaveCommentReactionProps } from "@/features/sales/components/type";

const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

const CommentCounter = styled.div`
  color: #797979;
  font-weight: 500;
  font-size: 14px;
`;

const Container = styled.div`
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
`;

type CommentsProps = {
  saleId: number;
  user: UserResponse | null;
  comments: SaleCommentResponse[];
  onSaveComment: (props: SaveCommentProps) => void;
  onDeleteComment: (props: BasicCommentProps) => void;
  onCommentReaction: (props: SaveCommentReactionProps) => void;
};

const SaleCommentList = ({
  saleId,
  user,
  comments,
  onSaveComment,
  onDeleteComment,
  onCommentReaction,
}: CommentsProps) => {
  return (
    <Container>
      <Header>
        <CommentCounter>댓글 {comments.length}개</CommentCounter>
      </Header>
      <CommentsContainer>
        {comments.map((comment) => (
          <SaleCommentItem
            key={comment.id}
            user={user}
            comment={comment}
            onSaveComment={onSaveComment}
            onDeleteComment={onDeleteComment}
            onCommentReaction={onCommentReaction}
          />
        ))}
      </CommentsContainer>
      <SaleCommentInput id={saleId} user={user} onSaveComment={onSaveComment} />
    </Container>
  );
};

export default SaleCommentList;
