import React from "react";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import StyledButton from "@/components/StyledButton";
import { ReportResponse } from "@/features/reports/interfaces";
import { colors, depths } from "@/styles/values";

const ReportDialogShade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${depths.overlayShade};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background-color: ${colors.black}aa;
`;

const ReportDialogBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 316px;
  max-width: 90vw;
  min-height: 100px;
  max-height: 90vh;
  max-height: calc(var(--vh, 1vh) * 90);
  padding: 26px 34px;

  background-color: ${colors.white};
`;

const Title = styled.div`
  margin-bottom: 22px;

  color: ${colors.dark};
  font-weight: 700;
  font-size: 15px;
  line-height: 32px;
`;

const ButtonBox = styled.div`
  width: 247px;
  margin-bottom: 10px;

  &.close {
    margin-top: 6px;
  }
`;

type Props = {
  busy?: boolean;
  error?: boolean;
  report?: ReportResponse;
  onClose: () => void;
};

const ReportDialog: React.FC<Props> = (props) => {
  const items = props.report && [
    ...(props.report.register_ledger?.register_ledger_url
      ? [
          <ButtonBox key="register">
            <StyledButton outlined href={props.report.register_ledger?.register_ledger_url} target="_blank">
              등기사항전부증명서
            </StyledButton>
          </ButtonBox>,
        ]
      : []),
    ...(props.report.building_ledger?.index_url
      ? [
          <ButtonBox key="building-index">
            <StyledButton outlined href={props.report.building_ledger?.index_url} target="_blank">
              건축물대장 총괄
            </StyledButton>
          </ButtonBox>,
        ]
      : []),
    ...(props.report.building_ledger?.complex_url
      ? [
          <ButtonBox key="building-complex">
            <StyledButton outlined href={props.report.building_ledger?.complex_url} target="_blank">
              건축물대장 표제부
            </StyledButton>
          </ButtonBox>,
        ]
      : []),
    ...(props.report.building_ledger?.primary_urls
      ? props.report.building_ledger.primary_urls.map((url, i) => (
          <ButtonBox key={`building-primary-${i}`}>
            <StyledButton outlined href={url} target="_blank">
              건축물대장 {i > 0 ? `(${i + 1})` : ""}
            </StyledButton>
          </ButtonBox>
        ))
      : []),
    ...(props.report.land_ledger?.land_ledger_url
      ? [
          <ButtonBox key="land">
            <StyledButton outlined href={props.report.land_ledger?.land_ledger_url} target="_blank">
              토지대장
            </StyledButton>
          </ButtonBox>,
        ]
      : []),
    ...(props.report.land_using_info?.land_using_info_url
      ? [
          <ButtonBox key="land-using">
            <StyledButton outlined href={props.report.land_using_info?.land_using_info_url} target="_blank">
              토지이용계획
            </StyledButton>
          </ButtonBox>,
        ]
      : []),
  ];

  return (
    <ReportDialogShade onClick={() => props.onClose()}>
      <ReportDialogBox>
        <Title>공문서 열람</Title>
        {props.busy && <LoadingCircle />}
        {items}
        <ButtonBox className="close">
          <StyledButton gray onClick={() => props.onClose()}>
            닫기
          </StyledButton>
        </ButtonBox>
      </ReportDialogBox>
    </ReportDialogShade>
  );
};

export default ReportDialog;
