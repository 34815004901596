import React, { useEffect } from "react";
import { PostType } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LandingContainer from "@/features/landing/containers/LandingContainer";
import { resetLimitedPostListState } from "@/redux/posts/actions";
import { fetchMoreLimitedPostListThunk } from "@/redux/posts/thunk";
import { getRandomReportFetchState } from "@/redux/report/selectors";
import { randomReportFetchThunk } from "@/redux/report/thunk";
import { getUrl } from "@/utils/routes";

export default function LandingPage() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const randomReportFetchState = useSelector(getRandomReportFetchState);

  useEffect(() => {
    dispatch(resetLimitedPostListState());
  }, []);

  useEffect(() => {
    if (randomReportFetchState === "READY") {
      dispatch(randomReportFetchThunk());
    }
  }, [randomReportFetchState]);

  useEffect(() => {
    if (pathname === getUrl("dashboard")) {
      dispatch(fetchMoreLimitedPostListThunk({ postTypes: [PostType.Notice, PostType.Maintenance] }));
    } else {
      dispatch(fetchMoreLimitedPostListThunk({ postTypes: [PostType.Notice, PostType.Maintenance] }));
    }
  }, [pathname]);

  return <LandingContainer />;
}
