import React from "react";
import styled from "styled-components";
import LoadingCircle from "./LoadingCircle";
import { lengths } from "@/styles/values";

export const CardBox = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-right: 20px;
  padding-bottom: 16px;
  padding-left: 20px;

  background-color: #fff;

  > div:not(:last-child) {
    margin-right: 12px;
  }

  border-bottom: 1px solid #eeeff1;

  &:last-child {
    border-bottom: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Title = styled.div`
  color: #333;
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 13px;
  }
`;

const SubTitle = styled.div`
  color: #6f6f6f;
  font-weight: 400;
  font-size: 12px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 12px;
  }
`;

const ActionsContainer = styled.div`
  > div,
  > button,
  > a {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const LeadingIconContainer = styled.div`
  margin-right: 16px;
`;

interface CardProps {
  leadingIcon?: React.ReactNode;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  loading?: boolean;
}

export default function Card({ leadingIcon, className, title, subTitle, actions, loading }: CardProps) {
  return (
    <CardBox className={className}>
      {leadingIcon && <LeadingIconContainer>{leadingIcon}</LeadingIconContainer>}
      <TitleContainer>
        {title && <Title>{title}</Title>}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </TitleContainer>

      {loading ? <LoadingCircle /> : <ActionsContainer>{actions}</ActionsContainer>}
    </CardBox>
  );
}
