import React from "react";
import { PostResponse, UserType } from "@doczip/api-client";
import { Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import PostModalOpenButton from "@/features/posts/components/PostModalOpenButton";
import { FetchState } from "@/interfaces/fetch";
import { getUserMe } from "@/redux/user/selectors";
import { lengths } from "@/styles/values";
import { toYmd } from "@/utils/datetime";
import { getUrl } from "@/utils/routes";

const GoToPostListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;

  font-weight: 400;
  font-size: 13px;

  background-color: white;
  border: 1px solid #dfe2e7;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 20px;

  border-bottom: 1px solid #dfe2e7;

  @media screen and (max-width: ${lengths.desktop}) {
    flex-direction: column;
    padding: 12px;
  }
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 13px;
  }
`;

const Date = styled.div`
  color: #797979;
  font-weight: 400;
  font-size: 12px;
`;

const ContentContainer = styled.div`
  margin-bottom: 24px;
  padding-top: 20px;
  padding-bottom: 40px;

  border-bottom: 1px solid #dfe2e7;

  @media screen and (max-width: ${lengths.desktop}) {
    padding-top: 16px;
    padding-right: 12px;
    padding-bottom: 30px;
    padding-left: 12px;
  }
`;

const Content = styled.div`
  min-height: 80px;

  font-weight: 400;
  font-size: 13px;
`;

const MenuContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding-bottom: 20px;

  @media screen and (max-width: ${lengths.desktop}) {
    justify-content: center;
  }
`;

type Props = {
  post: PostResponse;
  fetchState: FetchState;
  handleDelete?: () => void;
};

const Show: React.FC<Props> = ({ post, fetchState, handleDelete }: Props) => {
  const navigator = useNavigate();
  const user = useSelector(getUserMe);

  const handleGotoListButtonClick = () => {
    navigator(getUrl("postsRoot"));
  };

  if (post && fetchState === "SUCCESS") {
    return (
      <>
        <TitleContainer>
          <Title>{post.title}</Title>
          <Date>{toYmd(post.created_datetime)}</Date>
        </TitleContainer>
        <ContentContainer>
          <Content dangerouslySetInnerHTML={{ __html: post.content }} />
        </ContentContainer>
        <MenuContainer>
          {user?.user_type === UserType.Admin && handleDelete && (
            <>
              <PostModalOpenButton as={<GoToPostListButton>수정</GoToPostListButton>} post={post} />
              <GoToPostListButton onClick={handleDelete}>삭제</GoToPostListButton>
            </>
          )}
          <GoToPostListButton onClick={handleGotoListButtonClick}>목록</GoToPostListButton>
        </MenuContainer>
      </>
    );
  } else if ((!post && fetchState === "READY") || fetchState === "FETCHING") {
    return <LoadingCircle position="absolute" />;
  } else {
    return (
      <Alert severity="warning">공지사항 정보를 가져오는 도중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.</Alert>
    );
  }
};

export default Show;
