import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ReportLoanInfo from "@/features/reports/components/ReportLoanInfo";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportLoanInfoContainerProps {}

const ReportLoanInfoContainer: React.FC<ReportLoanInfoContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  const registerLedger = useMemo(() => {
    if (!report || !report?.report || !report?.report?.register_ledger) {
      return;
    }

    const {
      report: { register_ledger },
    } = report;

    return register_ledger;
  }, [report, reportFetchState]);

  return <ReportLoanInfo registerLedger={registerLedger} reportFetchState={reportFetchState} />;
};

export default ReportLoanInfoContainer;
