import React from "react";
import styled from "styled-components";
import CommonLayout from "@/components/CommonLayout";
import ReportReminderListContainer from "@/features/reports/containers/reminder/ReportReminderListContainer";
import ReportReminderSearchContainer from "@/features/reports/containers/reminder/ReportReminderSearchContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ReportReminderPage = () => {
  return (
    <CommonLayout>
      <Container>
        <ReportReminderSearchContainer />
        <ReportReminderListContainer />
      </Container>
    </CommonLayout>
  );
};

export default ReportReminderPage;
