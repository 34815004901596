import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { ReportRegisterLedger } from "@blitz-core/api-client";
import { UserResponse, RegisterLedgerOwnershipSummary, CountResponse } from "@doczip/api-client";
import { ButtonBase } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import TitledWrapper from "@/components/TitledWrapper";
import ReportOwnerAddressSearchModalOpenButton from "@/features/reports/components/ReportOwnerAddressSearchModalOpenButton";
import { FetchState } from "@/interfaces/fetch";
import { setSearchType } from "@/redux/logs/actions";
import { issuedReportLogCountFetchThunk } from "@/redux/logs/thunk";
import { colors } from "@/styles/values";
import { alert, confirm, Options } from "@/utils/dialog";
import { checkOwnerIsIndividual, formatSubscriptionType } from "@/utils/owner";
import { getUrl } from "@/utils/routes";

const ReportOwnerInfoContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

const ContentContainer = styled.div``;

const BuildingInfoListContainer = styled.div``;

const BuildingInfoList = styled.ul``;

const BuildingInfoItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Description = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 18px;
`;

const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    padding: 10px 0;

    color: ${colors.main};
    font-weight: 700;
    font-size: 13px;

    background-color: #fff;
    border: 1px solid ${colors.main};
  }
`;

interface ReportOwnerInfoProps {
  user: UserResponse | null;
  registerLedger?: ReportRegisterLedger;
  reportFetchState: FetchState;
  issuedReportLogCount?: CountResponse;
}

const ReportOwnerInfo: React.FC<ReportOwnerInfoProps> = ({
  user,
  registerLedger,
  reportFetchState,
  issuedReportLogCount,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const [issuedReportCountList, setIssuedReportCountList] = useState<number[]>([]);
  const reportSum = useMemo(
    () => (issuedReportCountList.length ? issuedReportCountList.reduce((acc, curr) => acc + curr, 0) : 0),
    [issuedReportCountList],
  );

  const getIssuedReportLogCount = useCallback(() => {
    if (!registerLedger || !registerLedger?.ownership_summaries) {
      return;
    }

    registerLedger.ownership_summaries.forEach((ownership) => {
      dispatch(
        issuedReportLogCountFetchThunk({
          ownername: ownership.owner ? ownership.owner.replace(/\(.*?\)/, "").trim() : "",
          ownerIdentityCode: ownership.identity_code,
        }),
      );
    });
  }, [registerLedger]);

  const confirmMessage = useMemo<[string, Options] | undefined>(() => {
    if (!issuedReportCountList) {
      return;
    }

    if (issuedReportCountList.length < 0) {
      return [
        "오너스클럽 회원으로 가입하시면 소유주가 가진 다른 부동산을 검색해보실 수 있습니다. 가입 페이지로 이동합니다.",
        { title: "오너스클럽에 가입하시겠습니까?" },
      ];
    }

    return [
      `오너스클럽 회원으로 가입하시면 소유주가 가진 다른 부동산 ${reportSum}개를 바로 열람해보실 수 있습니다. 가입 페이지로 이동합니다.`,
      { title: "오너스클럽에 가입하시겠습니까?" },
    ];
  }, [issuedReportCountList, reportSum]);

  const goToReportsIssued = (ownerInfo: RegisterLedgerOwnershipSummary) => {
    const owner = ownerInfo.owner && ownerInfo.owner.replace(/\(.*?\)/, "").trim();
    const identityCode = ownerInfo.identity_code;
    dispatch(setSearchType("owner"));
    navigate(getUrl("reportsIssued", { query: { query: owner, searchType: "owner", identityCode } }));
  };

  const checkIndividualAndGoToReportsIssued = async (ownerInfo: RegisterLedgerOwnershipSummary) => {
    if (checkOwnerIsIndividual(ownerInfo)) {
      if (
        await confirm(
          "소유주(권리자) 주소 검색은 본 서비스 내에 저장된 데이터베이스에서 소유자(권리자)의 성명과 생년월일이 일치하는 데이터를 찾아 출력해주는 서비스입니다. 조회 대상이 되는 소유주(권리자)의 개인정보를 침해하지 않도록 소유주(권리자)의 사전 동의나 승낙을 받으셨습니까? (동의나 승낙을 받지 않으신 경우 서비스를 제공하지 않으며, 서비스 제공자는 개인정보 관련 법적 책임을 부담하지 않습니다)",
          { title: "개인정보 침해 방지 안내", confirmText: "예", closeText: "아니오" },
        )
      ) {
        goToReportsIssued(ownerInfo);
      }
    } else {
      goToReportsIssued(ownerInfo);
    }
  };

  const checkOwnership = useCallback(
    async (type: "address" | "realEstate") => {
      if (!confirmMessage) {
        return;
      }
      if (!registerLedger || !registerLedger?.ownership_summaries) {
        alert("유저 정보가 존재하지 않습니다. 잠시후 다시 시도해주세요.");
        return false;
      }

      const { ownership_summaries } = registerLedger;

      if (ownership_summaries?.length === 1 && ownership_summaries[0].address) {
        switch (type) {
          case "address": {
            const address = ownership_summaries[0].address.split("(")[0];
            navigate(getUrl("dashboard", { query: { address } }));
            return false;
          }
          case "realEstate":
            if (
              typeof formatSubscriptionType(user?.subscription?.subscription_type) !== "string" &&
              user?.is_admin === false
            ) {
              if (await confirm(...confirmMessage)) {
                navigate(getUrl("userRoot", { query: { subscribe_modal: "open" } }));
              }
            } else {
              checkIndividualAndGoToReportsIssued(ownership_summaries[0]);
            }
            return false;
          default:
            return false;
        }
      }
      return true;
    },
    [confirmMessage],
  );

  const handleModalConfirm = useCallback(
    async (type: "address" | "realEstate", checkedOwner: RegisterLedgerOwnershipSummary) => {
      if (!confirmMessage) {
        return;
      }

      switch (type) {
        case "address":
          if (checkedOwner.address) {
            const splitAddress = checkedOwner.address.split("(")[0];
            navigate(getUrl("dashboard", { query: { address: splitAddress } }));
          }
          break;
        case "realEstate":
          if (
            typeof formatSubscriptionType(user?.subscription?.subscription_type) !== "string" &&
            user?.is_admin === false
          ) {
            if (await confirm(...confirmMessage)) {
              navigate(getUrl("userRoot", { query: { subscribe_modal: "open" } }));
            }
          } else if (checkedOwner.owner) {
            checkIndividualAndGoToReportsIssued(checkedOwner);
          }
          break;
        default:
          break;
      }
    },
    [confirmMessage],
  );

  const ownerAddressInfo = useMemo(() => {
    if (!issuedReportCountList) {
      return;
    }

    const initialAddressInfo = [
      {
        title: "소유주 주소 열람하기",
        onConfirm: (checkedOwner: RegisterLedgerOwnershipSummary) => handleModalConfirm("address", checkedOwner),
        onCheckBeforeModalOpen: () => checkOwnership("address"),
      },
    ];

    if (reportSum === 0) {
      return [...initialAddressInfo];
    }

    if (issuedReportCountList.length < 0) {
      return [
        ...initialAddressInfo,
        {
          title: "소유주가 가진 다른 부동산 찾아보기",
          onConfirm: (checkedOwner: RegisterLedgerOwnershipSummary) => handleModalConfirm("realEstate", checkedOwner),
          onCheckBeforeModalOpen: () => checkOwnership("realEstate"),
        },
      ];
    }

    const realEstateCountText = reportSum !== 0 ? `(${reportSum}건)` : "";
    return [
      ...initialAddressInfo,
      {
        title: `소유주가 가진 다른 부동산 찾아보기 ${realEstateCountText}`,
        onConfirm: (checkedOwner: RegisterLedgerOwnershipSummary) => handleModalConfirm("realEstate", checkedOwner),
        onCheckBeforeModalOpen: () => checkOwnership("realEstate"),
      },
    ];
  }, [issuedReportCountList, handleModalConfirm, checkOwnership, reportSum]);

  const getOwnerText = (owner: string | undefined, identityCode: string | undefined) => {
    if (!owner || !identityCode) {
      return "";
    }

    const isIndividualPerson = identityCode.split("-")[1] === "*******";
    const identityCodeText = isIndividualPerson ? `${identityCode.slice(0, 2)}년생` : "";

    return `${identityCodeText} ${owner}`;
  };

  const ownerDescription = useMemo(() => {
    if (
      !registerLedger ||
      !registerLedger.ownership_summaries ||
      !registerLedger.ownership_summaries.length ||
      reportFetchState === "FAILURE"
    ) {
      return "👦🏻 소유주 정보가 없습니다.";
    }

    const ownerCount =
      registerLedger.ownership_summaries.length > 1 ? `${registerLedger.ownership_summaries.length}명, ` : "";

    const ownerList = registerLedger.ownership_summaries
      .filter(({ owner }) => typeof owner !== "undefined")
      .map(({ owner, identity_code }, index) => getOwnerText(owner, identity_code))
      .reduce<React.ReactNode[]>(
        (acc, cur, idx) => [
          ...acc,
          ...(acc.length ? [<React.Fragment key={`separator-${idx}`}>, </React.Fragment>] : []),
          <React.Fragment key={`owner-${idx}`}>{cur}</React.Fragment>,
        ],
        [],
      );

    return (
      <React.Fragment key="owner-description">
        👦🏻 소유주는 {ownerCount}
        {ownerList}입니다.
      </React.Fragment>
    );
  }, [registerLedger, reportFetchState]);

  const ownerAddressView = useMemo(() => {
    if (reportFetchState === "FAILURE") {
      return;
    }
    if (reportFetchState === "FETCHING" || !ownerAddressInfo) {
      return <LoadingCircle />;
    }

    return ownerAddressInfo.map((info, index) => (
      <ReportOwnerAddressSearchModalOpenButton
        key={index}
        title={info.title}
        onConfirm={info.onConfirm}
        registerLedger={registerLedger}
        onCheckBeforeModalOpen={info.onCheckBeforeModalOpen}
        as={
          <ButtonContainer>
            <Button>{info.title}</Button>
          </ButtonContainer>
        }
      />
    ));
  }, [reportFetchState, ownerAddressInfo]);

  const content = useMemo(() => {
    if (reportFetchState === "FETCHING") {
      return <LoadingCircle />;
    }

    return (
      <div>
        <BuildingInfoListContainer>
          <BuildingInfoList>
            <BuildingInfoItem>
              <Description>{ownerDescription}</Description>
            </BuildingInfoItem>
          </BuildingInfoList>
        </BuildingInfoListContainer>

        {ownerAddressView}
      </div>
    );
  }, [reportFetchState, ownerAddressView]);

  useEffect(() => {
    getIssuedReportLogCount();
  }, [registerLedger]);

  // 첫 렌더 시 무시하고 그 이후 response가 바뀌었을 때 실행되는 hook
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (issuedReportLogCount) {
      // 정상적인 response.count은 최소값이 1이다. 현재 열람한 주소를 포함하기 때문이다.
      const count = issuedReportLogCount.count !== 0 ? issuedReportLogCount.count - 1 : issuedReportLogCount.count;
      setIssuedReportCountList([...issuedReportCountList, count]);
    }
  }, [issuedReportLogCount]);

  return (
    <>
      <TitledWrapper
        title="소유자 정보"
        titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
        backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
      >
        <ReportOwnerInfoContainer>
          <ContentContainer>{content}</ContentContainer>
        </ReportOwnerInfoContainer>
      </TitledWrapper>
    </>
  );
};

export default ReportOwnerInfo;
