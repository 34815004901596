import type { Routes, RouteParams } from "../AppRouter";
import { UnarrayReadonlyArray } from "./array";

type ExtendsRouteParamOrNever<RouteKey> = RouteKey extends keyof RouteParams ? RouteKey : never;

type RouteParamKeysOrNever<RouteParamKeys extends keyof RouteParams | never> = keyof RouteParams extends never
  ? never
  : UnarrayReadonlyArray<RouteParams[RouteParamKeys]>;

export function getUrl<
  RouteKey extends keyof Routes,
  RouteParamKeys extends RouteParamKeysOrNever<ExtendsRouteParamOrNever<RouteKey>>,
>(
  name: RouteKey,
  options: { params?: { [key in RouteParamKeys]: string | number }; query?: Record<string, any> } = {},
): string {
  if (typeof window.DOCZIP__ROUTES === "undefined") {
    throw Error("getUrl() of route util is not available before AppRouter is rendered.");
  }

  const routes = window.DOCZIP__ROUTES;

  let path: string = routes[name].props.path;

  const { params, query } = options;

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value !== "string" && typeof value !== "number") {
        return;
      }

      const stringValue = value.toString();

      path = path.replace(new RegExp(`/:${key}/`, "g"), `/${stringValue}/`);
      path = path.replace(new RegExp(`/:${key}$`), `/${stringValue}`);
    });
  }

  if (query) {
    const urlParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => urlParams.append(key, value));
    path = `${path}?${urlParams.toString()}`;
  }

  return path;
}
