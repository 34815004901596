import React from "react";
import classnames from "classnames";
import { decamelizeKeys } from "humps";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@/styles/values";

const StyledButtonBox = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  margin: 0;
  padding: 0 8px;

  color: ${colors.white};
  font-size: 14px;

  background-color: ${colors.main};
  outline: none;
  cursor: pointer;

  transition: all 0.2s ease;

  &.row {
    flex-flow: row nowrap;
    gap: 8px;
  }

  &.gray {
    color: ${colors.dark};

    background-color: #f2f2f2;

    &:hover {
      background-color: #f2f2f299;
    }
  }

  &.white {
    color: ${colors.dark};

    background-color: ${colors.white};

    &:hover {
      background-color: #f2f2f2;
    }
  }

  &.dark {
    color: ${colors.white};

    background-color: ${colors.dark};

    &:hover {
      background-color: ${colors.darkerGray};
    }
  }

  &.outlined {
    color: ${colors.main};

    background-color: ${colors.white};
    border: 1px solid ${colors.main};

    &:hover {
      color: ${colors.white};

      background-color: ${colors.main};
    }
  }

  &.graylined {
    color: rgba(21, 25, 32, 0.5);

    background-color: ${colors.white};
    border: 1px solid rgba(86, 103, 137, 0.26);

    &:hover {
      background-color: #f2f2f2;
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.disabled {
    color: #797979;

    background-color: #f2f2f2;
    cursor: not-allowed;

    pointer-events: none;

    &:hover {
      color: #797979;

      background-color: #f2f2f2;
    }
  }

  &.full-width {
    width: 100%;
  }

  &:hover {
    background-color: ${colors.main}99;
  }
`;

type Props = {
  className?: string;
  row?: boolean;
  bold?: boolean;
  outlined?: boolean;
  graylined?: boolean;
  gray?: boolean;
  white?: boolean;
  dark?: boolean;
  disabled?: boolean;
  href?: string;
  to?: React.ComponentProps<typeof Link>["to"];
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: string;
  fontSize?: string;
  height?: string;
  fullWidth?: boolean;
};

const StyledButton: React.FC<Props> = (props) => {
  const { className, row, bold, outlined, graylined, gray, white, dark, disabled, fullWidth } = props;
  const classNames = classnames(
    className,
    decamelizeKeys({ row, bold, outlined, graylined, gray, white, dark, disabled, fullWidth }, { separator: "-" }),
  );
  const style = {
    ...(props.fontSize ? { fontSize: props.fontSize } : {}),
    ...(props.height ? { height: props.height } : {}),
  };
  if (props.to) {
    return (
      <StyledButtonBox
        className={classNames}
        style={style}
        as={Link}
        to={props.to}
        onClick={props.onClick}
        target={props.target}
      >
        {props.children}
      </StyledButtonBox>
    );
  } else {
    return (
      <StyledButtonBox
        className={classNames}
        style={style}
        href={props.href}
        onClick={props.onClick}
        target={props.target}
      >
        {props.children}
      </StyledButtonBox>
    );
  }
};

export default StyledButton;
