import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as CrownIcon } from "@/assets/icons/crown.svg";
import { BackdropWithLoadingCircle } from "@/components/Backdrop";
import CardButton, { CardPrimaryButton } from "@/components/buttons/CardButton";
import ConfirmButton from "@/components/buttons/ConfirmButton";
import Card from "@/components/Card";
import CardButtonContainer from "@/components/CardButtonContainer";
import Checkbox, { CheckboxFormControlLabel } from "@/components/Checkbox";
import Divider from "@/components/Divider";
import DownloadTicketCard from "@/components/DownloadTicketCard";
import { ChargeDownloadTicketTerms } from "@/components/PaymentTerms";
import Select from "@/components/Select";
import SubscribeModalWithIamportLoader from "@/components/SubscribeModal";
import TextField from "@/components/TextField";
import TitledContainer from "@/components/TitledContainer";
import IamportLoader from "@/containers/IamportLoader";
import {
  PaymentMethodLabel,
  PriceLabel,
  SubPrice,
  TotalPrice,
  TransferNameContainer,
  TransferNameContainerDescription,
  UserNewTransactionContentContainer,
} from "@/features/user/components/transaction/UserNewTansactionLayout";
import UserTransactionListLayout, {
  UserTransactionListLayoutInnerContainer,
} from "@/features/user/components/transaction/UserTransactionListLayout";
import UserCommonLayout from "@/features/user/components/UserCommonLayout";
import useUserData from "@/features/user/hooks/useUserData";
import { DOWNLOAD_TICKET_PRICE_WITH_VAT } from "@/interfaces/downloadTicketPrice";
import { PaymentMethod } from "@/interfaces/paymentMethod";
import { getChargeFetchState } from "@/redux/transaction/selectors";
import { chargeThunk } from "@/redux/transaction/thunk";
import { getUserMe } from "@/redux/user/selectors";
import { fetchUserMeThunk } from "@/redux/user/thunk";
import { colors } from "@/styles/values";
import { getUrl } from "@/utils/routes";

type StyledCardProps = {
  subscribed?: boolean;
};

const StyledCard = styled(Card)<StyledCardProps>`
  word-break: keep-all;

  border: ${(props) => (props.subscribed === true ? "none" : `3px solid ${colors.main}`)};
`;

const TICKET_AMOUNT_OPTION_LIST: number[] = [1, 3, 5, 10, 15, 20, 30, 50, 100, 500, 1000];

const PAYMENT_METHOD_OPTION_LIST: PaymentMethod[] = ["Card", "Transfer"];

interface NewTransactionField {
  amount: number;
  method: PaymentMethod;
  name?: string;
  termsAgree?: boolean;
}

interface UserTransactionNewContainerProps {
  initialModalOpen?: boolean;
}

interface UserTransactionNewPageProps {}

function UserTransactionNewPage({ initialModalOpen }: UserTransactionNewContainerProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMe = useSelector(getUserMe);
  const chargeFetchState = useSelector(getChargeFetchState);
  const { subscribed } = useUserData();
  const [modalOpen, setModalOpen] = useState(initialModalOpen ?? false);

  const toggleModalOpen = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, [modalOpen]);

  const onSubscriptionButtonClick = () => {
    navigate(getUrl("userSubscriptions"));
  };

  useEffect(() => {
    if (chargeFetchState !== "SUCCESS") {
      return;
    }

    dispatch(fetchUserMeThunk({}));

    navigate(getUrl("userTransactionsRoot"));
  }, [chargeFetchState]);

  const handleAmountChange = (amount: number) => {
    setFieldValue("amount", amount);
  };

  const handlePaymnetMethodChange = (method: PaymentMethod) => {
    setFieldValue("method", method);
  };

  const handleTermsCheck = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setFieldValue("termsAgree", checked);
  };

  const { handleSubmit, values, errors, getFieldProps, setFieldValue, setFieldError } = useFormik<NewTransactionField>({
    initialValues: {
      amount: 10,
      method: "Card",
    },
    onSubmit: ({ amount, method, name }) => {
      if (!userMe) {
        return;
      }

      if (method === "Transfer" && !name) {
        setFieldError("name", "실명을 입력해 주세요");
        return;
      }

      dispatch(
        chargeThunk({
          user: userMe,
          price: amount * DOWNLOAD_TICKET_PRICE_WITH_VAT,
          method,
          name,
        }),
      );
    },
  });

  return (
    <UserCommonLayout>
      {modalOpen && <SubscribeModalWithIamportLoader open={modalOpen} onClose={toggleModalOpen} />}
      <UserTransactionListLayout>
        <UserTransactionListLayoutInnerContainer>
          {chargeFetchState === "FETCHING" && <BackdropWithLoadingCircle />}
          <DownloadTicketCard hideChargeButton />
          <StyledCard
            subscribed={subscribed}
            leadingIcon={<CrownIcon />}
            title="오너스클럽 정기결제"
            actions={
              <CardButtonContainer>
                {subscribed ? (
                  <>
                    <CardPrimaryButton onClick={onSubscriptionButtonClick}>결제내역</CardPrimaryButton>
                  </>
                ) : (
                  <>
                    <CardPrimaryButton onClick={toggleModalOpen}>신청하기</CardPrimaryButton>
                    <CardButton onClick={onSubscriptionButtonClick}>결제내역</CardButton>
                  </>
                )}
              </CardButtonContainer>
            }
          />

          <UserNewTransactionContentContainer onSubmit={handleSubmit}>
            <TitledContainer title="다운로드권 구매하기">
              <Select
                value={values.amount}
                optionList={TICKET_AMOUNT_OPTION_LIST}
                onChange={handleAmountChange}
                newValueToOptionType={(numberText) => Number(numberText)}
                renderOption={(amount) => <PriceLabel amount={amount} />}
              />
            </TitledContainer>
            <Divider />
            <TitledContainer title="결제수단 선택">
              <Select
                value={values.method}
                optionList={PAYMENT_METHOD_OPTION_LIST}
                onChange={handlePaymnetMethodChange}
                renderOption={(method, selected) => <PaymentMethodLabel selected={selected} method={method} />}
              />
              {values.method === "Transfer" && (
                <TransferNameContainer>
                  <TransferNameContainerDescription>
                    실시간 계좌이체에 고객님의 실명이 필요합니다.
                  </TransferNameContainerDescription>
                  <TextField
                    {...getFieldProps("name")}
                    error={!!errors.name}
                    helperText={errors.name}
                    placeholder="가입할 때 입력한 실명"
                  />
                </TransferNameContainer>
              )}
            </TitledContainer>
            <Divider />
            <TitledContainer title="총 결제 금액" actions={<TotalPrice amount={values.amount} />}>
              <SubPrice title="대법원 수수료" amount={values.amount} />
              <SubPrice title="부가세" amount={values.amount} isTax />
            </TitledContainer>
            <CheckboxFormControlLabel
              control={<Checkbox sx={{ marginLeft: "-10px", marginTop: "-10px", marginBottom: "-10px" }} />}
              label="아래 이용 안내 및 결제 진행에 동의합니다."
              checked={values.termsAgree}
              onChange={handleTermsCheck}
            />
            <ConfirmButton disabled={!values.termsAgree} type="submit" sx={{ marginTop: "32px" }}>
              구매하기
            </ConfirmButton>
          </UserNewTransactionContentContainer>
          <ChargeDownloadTicketTerms />
        </UserTransactionListLayoutInnerContainer>
      </UserTransactionListLayout>
    </UserCommonLayout>
  );
}

export default function UserTransactionNewPageWithIamportLoader(props: UserTransactionNewPageProps) {
  return (
    <IamportLoader>
      <UserTransactionNewPage {...props} />
    </IamportLoader>
  );
}
