import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ReportLogResponse } from "api/dist";
import { reportApi } from "@/client";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import Checkbox from "@/components/Checkbox";
import CommonLayout from "@/components/CommonLayout";
import MobileInnerCommonLayout from "@/components/MobileInnerCommonLayout";
import { NoResultChangeNotification } from "@/components/NoResult";
import StyledButton from "@/components/StyledButton";
import TitledContainer from "@/components/TitledContainer";
import ReportReminderModal from "@/features/reports/components/reminder/ReportReminderModal";
import { resetReportLogListState } from "@/redux/logs/actions";
import { getReportLogListState } from "@/redux/logs/selectors";
import { fetchMoreReportLogListThunk } from "@/redux/logs/thunk";
import { colors, lengths } from "@/styles/values";
import { format, getExpirationMessage } from "@/utils/datetime";
import "react-toastify/dist/ReactToastify.css";

const MenuButton = styled(StyledButton)`
  height: 36px;
  padding: 9.5px 14px;

  font-weight: 500;
  font-size: 12px;
`;

interface AllSelected {
  isAll?: boolean;
}

const FilterButton = styled(MenuButton)<AllSelected>`
  ${({ isAll }) =>
    isAll === true
      ? "color: #ff8f17 !important; border-color: #ff8f17 !important;"
      : "color: #797979 !important; border-color: #9a9a9a !important;"}
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const SearchField = styled.input`
  flex: 1;
  height: 48px;
  padding: 15px 12px;

  font-weight: 400;
  font-size: 13px;

  border: 1px solid #dfe2e7;
  outline: none;

  ::placeholder {
    color: #797979;
  }

  &:focus {
    border-color: ${colors.main};
  }
`;

const ListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media screen and (max-width: ${lengths.desktop}) {
    gap: 8px;
  }
`;

const UpArrowIcon = styled.div`
  width: 16px;
  height: 16px;

  background-image: url("/images/arrow-upward.svg");
  background-size: 100% 100%;
`;

const DownArrowIcon = styled.div`
  width: 16px;
  height: 16px;

  background-image: url("/images/arrow-downward.svg");
  background-size: 100% 100%;
`;

const SettingsIcon = styled.div`
  width: 22px;
  height: 22px;

  background-image: url("/images/settings.svg");
  background-size: 100% 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const List = styled.div`
  background-color: white;
`;

const RowItem = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 16px;

  border-bottom: 1px solid #eeeff1;

  &:last-child {
    border-bottom: none;
  }
`;

const RowItemRightArea = styled.div`
  padding-left: 12px;
`;

const RowInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2px;
  padding-left: 12px;

  color: #333;
  font-weight: 400;
  font-size: 13px;
`;

type ExiryProps = {
  state: number;
};

const ExpiryDate = styled.span<ExiryProps>`
  display: inline-block;
  padding-right: 32px;

  color: ${(props) => (props.state && props.state === 1 ? "#e84848" : "#ff8f17")};
  font-weight: 700;
  font-size: 13px;

  @media screen and (max-width: ${lengths.desktop}) {
    display: none;
  }
`;

const ExpiryDateMobile = styled.span<ExiryProps>`
  display: none;
  padding-right: 32px;

  color: ${(props) => (props.state && props.state === 1 ? "#e84848" : "#ff8f17")};
  font-weight: 700;
  font-size: 11px;

  @media screen and (max-width: ${lengths.desktop}) {
    display: inline-block;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 13px;
  }
`;

const StyledDate = styled.div`
  color: #718096;
  font-size: 12px;

  @media screen and (max-width: ${lengths.desktop}) {
    font-size: 11px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ButtonBox = styled.div`
  width: 120px;

  @media screen and (max-width: ${lengths.desktop}) {
    max-width: 66px;
  }
`;

const ReportReminderSettingPage = () => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState<ReportLogResponse[]>([]);
  const [, setError] = useState(false);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [allIdsMatch, setAllIdsMatch] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCreatedAsc, setIsCreatedAsc] = useState<boolean>(true);
  const [isExpiryAsc, setIsExpiryAsc] = useState<boolean>(true);
  const [isNoSelectionDialogOpen, setIsNoSelectionDialogOpen] = useState(false);
  const { value: reportLogList, fetchState: reportLogListFetchState } = useSelector(getReportLogListState);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(fetchMoreReportLogListThunk({ deleted: false, changeNotificationRequested: true }));
  }, []);

  useEffect(() => {
    fetchChangedReportLog();
  }, [reportLogListFetchState]);

  const fetchChangedReportLog = async () => {
    try {
      const filteredReports =
        reportLogList &&
        reportLogList.filter(
          (report) => report.change_notification_requested_datetime || report.change_notification_expired_datetime,
        );

      if (filteredReports) {
        setReports(filteredReports);
      }
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    if (reports.length > 0) {
      const reportIds = reports.map((report) => report.id);
      if (selectedIds.length !== reportIds.length) {
        setAllIdsMatch(false);
      }

      const sortedReportIds = [...reportIds].sort((a, b) => a - b);
      const sortedSelectedIds = [...selectedIds].sort((a, b) => a - b);

      const allMatch = sortedReportIds.every((id, index) => id === sortedSelectedIds[index]);
      setAllIdsMatch(allMatch);
    } else {
      setAllIdsMatch(false);
    }
  }, [reports, selectedIds]);

  const handleSelectedIds = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((item) => item !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };

  // 연장하기, 삭제하기 클릭 시 발동
  const handleOpenDialog = (type: "extend" | "delete") => {
    if (selectedIds.length === 0) {
      setIsNoSelectionDialogOpen(true);
      return;
    }
    if (type === "extend") {
      setReminderOpen(true);
    }
    if (type === "delete") {
      setIsDeleteDialogOpen(true);
    }
  };

  // 연장하기 성공/실패 토스트 띄우기 + 리로드
  const handleReminderAccept = async (count: number) => {
    if (selectedIds.length === 0) {
      toast.warn("선택된 주소가 없습니다.", { position: "bottom-right" });
      return;
    }

    try {
      const results = await Promise.all(
        selectedIds.map(async (id) => {
          let successCount = 0;
          let failureCount = 0;

          try {
            await reportApi.requestChangeNotification(id, {
              headers: { "Content-Type": "application/json" },
              data: {
                request_months: count,
              },
            });
            setTimeout(() => {}, 1000);
            successCount += 1;
          } catch (err) {
            failureCount += 1;
          }

          return {
            id,
            successCount,
            failureCount,
          };
        }),
      );

      const successMessages = results
        .filter(({ successCount }) => successCount > 0)
        .map(({ id, successCount }) => `ID ${id}: ${successCount}회 성공`);

      const failureMessages = results
        .filter(({ failureCount }) => failureCount > 0)
        .map(({ id, failureCount }) => `ID ${id}: ${failureCount}회 실패`);

      if (successMessages.length > 0) {
        toast.success(`알람 연장 요청 성공:\n${successMessages.join("\n")}`, { position: "bottom-right" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      if (failureMessages.length > 0) {
        toast.error(`알람 연장 요청 실패:\n${failureMessages.join("\n")}`, { position: "bottom-right" });
      }

      setReminderOpen(false);
    } catch (err) {
      toast.error("알람 연장 요청 중 전체 오류가 발생했습니다.", { position: "bottom-right" });
    }
  };

  const handleConfirmDelete = async () => {
    setIsDeleteDialogOpen(false);
    if (selectedIds.length > 0) {
      try {
        const results = await Promise.all(
          selectedIds.map((id) =>
            reportApi
              .stopChangeNotification(id)
              .then(() => ({ id, success: true }))
              .catch((error) => ({ id, success: false, error: error.message || "알 수 없는 오류" })),
          ),
        );

        const successfulRequests = results.filter((result) => result.success);
        const failedRequests = results.filter((result) => !result.success);

        if (successfulRequests.length > 0) {
          toast.success(`알람을 중지합니다: ${successfulRequests.map((result) => result.id).join(", ")}`, {
            position: "bottom-right",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }

        if (failedRequests.length > 0) {
          failedRequests.forEach(({ id }) => {
            toast.error(`ID ${id} 알림 중지 실패`, { position: "bottom-right" });
          });
        }
      } catch (err) {
        toast.error("알림 중지 요청 중 전체 오류 발생.", { position: "bottom-right" });
      }
    } else {
      toast.warn("선택된 주소가 없습니다.", { position: "bottom-right" });
    }
  };

  // 생성일 기준으로 정렬
  const handleSortByCreated = () => {
    setIsCreatedAsc((prev) => !prev);
    setReports((prevReports) =>
      [...prevReports].sort((a, b) =>
        isCreatedAsc
          ? new Date(b.created_datetime).getTime() - new Date(a.created_datetime).getTime()
          : new Date(a.created_datetime).getTime() - new Date(b.created_datetime).getTime(),
      ),
    );
  };

  // 만료일 기준으로 정렬
  const handleSortByExpiry = () => {
    setIsExpiryAsc((prev) => !prev);
    setReports((prevReports) =>
      [...prevReports].sort((a, b) =>
        isExpiryAsc
          ? new Date(b.change_notification_expired_datetime || 0).getTime() -
            new Date(a.change_notification_expired_datetime || 0).getTime()
          : new Date(a.change_notification_expired_datetime || 0).getTime() -
            new Date(b.change_notification_expired_datetime || 0).getTime(),
      ),
    );
  };

  // 전체 선택/해제
  const handleToggleAllSelectedIds = () => {
    const reportIds = reports.map((report) => report.id);

    if (allIdsMatch) {
      setSelectedIds([]);
    } else {
      setSelectedIds([...reportIds]);
    }
  };

  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSubmitQuery = () => {
    dispatch(resetReportLogListState());
    dispatch(
      fetchMoreReportLogListThunk({ registeredAddress: query, deleted: false, changeNotificationRequested: true }),
    );
  };

  return (
    <>
      <ReportReminderModal open={reminderOpen} onAccept={handleReminderAccept} onClose={() => setReminderOpen(false)} />

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>삭제 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>선택한 알림을 중지하시겠습니까? 이 작업은 되돌릴 수 없습니다.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="inherit">
            취소
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* 선택한 등기부가 없을 때 노출 */}
      <Dialog open={isNoSelectionDialogOpen} onClose={() => setIsNoSelectionDialogOpen(false)}>
        <DialogTitle>선택 필요</DialogTitle>
        <DialogContent>
          <DialogContentText>등기부를 선택해주세요!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNoSelectionDialogOpen(false)} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <CommonLayout>
        <Container>
          <MobileInnerCommonLayout>
            <TitledContainer title="설정된 알림 검색" />
            <SearchContainer>
              <SearchField
                placeholder="변동 알림 키워드 검색 (지역, 아파트 이름)"
                value={query}
                onChange={(e) => handleChangeQuery(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmitQuery();
                  }
                }}
              />
              <ButtonBox>
                <PrimaryButton fullWidth onClick={handleSubmitQuery}>
                  검색
                </PrimaryButton>
              </ButtonBox>
            </SearchContainer>
          </MobileInnerCommonLayout>

          <ListContainer>
            <MobileInnerCommonLayout px={16}>
              <ListHeaderContainer>
                <ButtonsContainer>
                  <FilterButton graylined isAll={allIdsMatch} onClick={handleToggleAllSelectedIds}>
                    전체선택
                  </FilterButton>
                  <FilterButton graylined onClick={handleSortByCreated}>
                    최신순 {isCreatedAsc ? <UpArrowIcon /> : <DownArrowIcon />}
                  </FilterButton>
                  <FilterButton graylined onClick={handleSortByExpiry}>
                    만기일순 {isExpiryAsc ? <UpArrowIcon /> : <DownArrowIcon />}
                  </FilterButton>
                </ButtonsContainer>
                <ButtonsContainer>
                  <MenuButton outlined onClick={() => handleOpenDialog("extend")}>
                    연장하기
                  </MenuButton>
                  <MenuButton outlined onClick={() => handleOpenDialog("delete")}>
                    삭제하기
                  </MenuButton>
                </ButtonsContainer>
              </ListHeaderContainer>
            </MobileInnerCommonLayout>
            <List>
              {reports.length > 0 ? (
                reports.map((item, index) => (
                  <RowItem key={index}>
                    <Checkbox
                      sx={{ padding: "0px", width: "20px", height: "20px" }}
                      onClick={() => handleSelectedIds(item.id)}
                      checked={selectedIds.includes(item.id)}
                    />
                    <RowInfo>
                      <FlexBox>
                        {item.change_notification_expired_datetime && (
                          <ExpiryDateMobile state={item.change_notification_expired_datetime ? 1 : 0}>
                            {item.change_notification_expired_datetime}
                          </ExpiryDateMobile>
                        )}
                      </FlexBox>
                      <Title>{item.registered_address}</Title>
                      <StyledDate>{format(item.created_datetime, "yyyy-MM-dd a h:mm")}</StyledDate>
                    </RowInfo>
                    {item.change_notification_expired_datetime && (
                      <ExpiryDate
                        state={
                          new Date(item.change_notification_expired_datetime).getTime() < new Date().getTime() ? 1 : 0
                        }
                      >
                        {getExpirationMessage(item.change_notification_expired_datetime)}
                      </ExpiryDate>
                    )}
                    <RowItemRightArea>
                      <SettingsIcon />
                    </RowItemRightArea>
                  </RowItem>
                ))
              ) : (
                <NoResultChangeNotification
                  title="아직 변동 알림 설정된 등기부등본이 없습니다."
                  description="🔍 '내 열람내역'에서 변동알림 설정을 해주세요."
                />
              )}
            </List>
          </ListContainer>
        </Container>
      </CommonLayout>
    </>
  );
};

export default ReportReminderSettingPage;
