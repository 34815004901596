import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaleList from "@/features/sales/components/SaleList";
import { resetLimitedSaleListState } from "@/redux/sales/actions";
import {
  getCreateSaleFetchState,
  getDeleteSaleFetchState,
  getLimitedSaleList,
  getLimitedSaleListFetchState,
  getLimitedSaleListPage,
  getLimitedSaleListTotalPage,
  getUpdateSaleFetchState,
} from "@/redux/sales/selectors";
import { fetchMoreLimitedSaleListThunk } from "@/redux/sales/thunk";

const SaleListContainer = () => {
  const limitedSaleList = useSelector(getLimitedSaleList);
  const limitedSaleListFetchState = useSelector(getLimitedSaleListFetchState);
  const limitedSaleListPage = useSelector(getLimitedSaleListPage);
  const limitedSaleListTotalPage = useSelector(getLimitedSaleListTotalPage);
  const deleteSaleFetchState = useSelector(getDeleteSaleFetchState);
  const createSaleFetchState = useSelector(getCreateSaleFetchState);
  const updateSaleFetchState = useSelector(getUpdateSaleFetchState);

  const dispatch = useDispatch();

  const handleLoadMoreLimitedSaleList = () => {
    dispatch(fetchMoreLimitedSaleListThunk());
  };

  useEffect(() => {
    if (deleteSaleFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetLimitedSaleListState());
    dispatch(fetchMoreLimitedSaleListThunk());
  }, [deleteSaleFetchState]);

  useEffect(() => {
    if (createSaleFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetLimitedSaleListState());
    dispatch(fetchMoreLimitedSaleListThunk());
  }, [createSaleFetchState]);

  useEffect(() => {
    if (updateSaleFetchState !== "SUCCESS") {
      return;
    }

    dispatch(resetLimitedSaleListState());
    dispatch(fetchMoreLimitedSaleListThunk());
  }, [updateSaleFetchState]);

  return (
    <SaleList
      list={limitedSaleList}
      fetchState={limitedSaleListFetchState}
      page={limitedSaleListPage}
      totalPage={limitedSaleListTotalPage}
      onLoadMore={handleLoadMoreLimitedSaleList}
    />
  );
};

export default SaleListContainer;
