import React from "react";
import styled from "styled-components";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import MobileInnerCommonLayout from "@/components/MobileInnerCommonLayout";
import TitledContainer from "@/components/TitledContainer";
import { colors, lengths } from "@/styles/values";

const SearchContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const SearchField = styled.input`
  flex: 1;
  height: 48px;
  padding: 15px 12px;

  font-weight: 400;
  font-size: 13px;

  border: 1px solid #dfe2e7;
  outline: none;

  ::placeholder {
    color: #797979;
  }

  &:focus {
    border-color: ${colors.main};
  }
`;

const ButtonBox = styled.div`
  width: 120px;

  @media screen and (max-width: ${lengths.desktop}) {
    max-width: 66px;
  }
`;

const ReportReminderSearchContainer = () => {
  return (
    <div>
      <MobileInnerCommonLayout>
        <TitledContainer title="변동 알림 검색" />
        <SearchContainer>
          <SearchField placeholder="변동 알림 키워드 검색 (지역, 아파트 이름)" />
          <ButtonBox>
            <PrimaryButton fullWidth>검색</PrimaryButton>
          </ButtonBox>
        </SearchContainer>
      </MobileInnerCommonLayout>
    </div>
  );
};

export default ReportReminderSearchContainer;
