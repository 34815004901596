import React from "react";
import { ReportLogResponse } from "@doczip/api-client";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import TitledWrapper from "@/components/TitledWrapper";
import ReportMemoEditForm from "@/features/reports/components/ReportMemoEditForm";
import { FetchState } from "@/interfaces/fetch";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ReportMemoProps {
  report: ReportLogResponse | null;
  reportFetchState: FetchState;
}

const ReportMemo: React.FC<ReportMemoProps> = ({ report, reportFetchState }) => {
  return (
    <TitledWrapper
      title="메모"
      titleStyle={{ size: 16, weight: 700, color: "#333333", bottomGap: 16 }}
      backgroundStyle={{ color: "#ffffff", padding: "23px 30px" }}
    >
      {reportFetchState !== "FETCHING" ? (
        <Container>
          <ReportMemoEditForm report={report} reportFetchState={reportFetchState} />
        </Container>
      ) : (
        <LoadingCircle size={16} />
      )}
    </TitledWrapper>
  );
};

export default ReportMemo;
