import React, { useEffect, useState } from "react";
import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { clearAccessCode, setAccessCode } from "@/client";
import Layout from "@/components/Layout";
import NotFound from "@/components/NotFound";
import ReportLoadingDialog from "@/features/reports/components/ReportLoadingDialog";
import ReportNoticeModal from "@/features/reports/components/ReportNoticeModal";
import ReportAddressInfoContainer from "@/features/reports/containers/ReportAddressInfoContainer";
import ReportBuildingInfoContainer from "@/features/reports/containers/ReportBuildingInfoContainer";
import ReportDownloadDocumentContainer from "@/features/reports/containers/ReportDownloadDocumentContainer";
import ReportLoanInfoContainer from "@/features/reports/containers/ReportLoanInfoContainer";
import ReportMapContainer from "@/features/reports/containers/ReportMapContainer";
import ReportMemoContainer from "@/features/reports/containers/ReportMemoContainer";
import ReportMolitListContainer from "@/features/reports/containers/ReportMolitListContainer";
import ReportOwnerInfoContainer from "@/features/reports/containers/ReportOwnerInfoContainer";
import ReportPriceInfoContainer from "@/features/reports/containers/ReportPriceInfoContainer";
import ReportShareContainer from "@/features/reports/containers/ReportShareContainer";
import ReportShowFooterContainer from "@/features/reports/containers/ReportShowFooterContainer";
import {
  resetKabPriceState,
  resetKbPriceState,
  resetMolitApartmentKindListingsState,
  resetRootReportState,
  resetTankerPriceState,
} from "@/redux/report/actions";
import { getMemoFetchState, getReport, getReportFetchState } from "@/redux/report/selectors";
import {
  kabPriceFetchThunk,
  kbPriceFetchThunk,
  molitApartmentKindListingsFetchThunk,
  reportFetchThunk,
  tankerPriceFetchThunk,
} from "@/redux/report/thunk";
import { lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  > div {
    max-width: 640px;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: ${lengths.desktop}) {
    padding: 24px 24px 40px;
  }
`;

type Props = {};

const ReportShowContainer: React.FC<Props> = () => {
  const navigate = useNavigate();
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);
  const memoFetchState = useSelector(getMemoFetchState);

  const location = useLocation();
  const dispatch = useDispatch();
  const { reportId: rawReportId } = useParams();
  const search = parse(location.search);
  const code = search.code ? (search.code as string) : void 0;

  const [reportId, setReportId] = useState<number>();
  const [providerId, setProviderId] = useState<number>();

  useEffect(() => {
    return () => {
      dispatch(resetRootReportState());
    };
  }, []);

  useEffect(() => {
    if (code) {
      setAccessCode(code);
    } else {
      clearAccessCode();
    }

    return () => {
      clearAccessCode();
    };
  }, [code]);

  useEffect(() => {
    if (!reportId) {
      return;
    }

    dispatch(resetRootReportState());
    dispatch(reportFetchThunk({ reportId, code }));
  }, [reportId, memoFetchState]);

  useEffect(() => {
    if (rawReportId) {
      setReportId(parseInt(rawReportId, 10));
    }
  }, [rawReportId]);

  useEffect(() => {
    dispatch(resetKbPriceState());
    dispatch(resetKabPriceState());
    dispatch(resetTankerPriceState());
    dispatch(resetMolitApartmentKindListingsState());

    if (report && report?.provider_id) {
      setProviderId(report.provider_id);

      dispatch(kbPriceFetchThunk({ reportId: report.provider_id }));
      dispatch(kabPriceFetchThunk({ reportId: report.provider_id }));
      dispatch(tankerPriceFetchThunk({ reportId: report.provider_id }));
      if (report.report?.molit_contract?.molit_apartment_kind_id) {
        dispatch(
          molitApartmentKindListingsFetchThunk({
            molitApartmentKindId: report.report?.molit_contract?.molit_apartment_kind_id,
            tradeType: "DEAL",
          }),
        );
      }
    }
  }, [report, reportFetchState]);

  if (reportFetchState === "READY" || reportFetchState === "FETCHING") {
    return <ReportLoadingDialog />;
  }
  if (!reportId || !providerId) {
    return <NotFound />;
  }

  return (
    <Container>
      <ReportNoticeModal reportId={report?.id} />
      <Layout mobileTitle="권리관계 분석 결과" onMobileBackClick={() => navigate(getUrl("reportsRoot"))} />
      <ReportAddressInfoContainer />
      {/* <ReportChangeDetectContainer /> */}
      <ReportOwnerInfoContainer />
      <ReportLoanInfoContainer />
      <ReportBuildingInfoContainer />
      <ReportPriceInfoContainer />
      <ReportMapContainer />
      <ReportDownloadDocumentContainer />
      <ReportShareContainer />
      <ReportMolitListContainer />
      <ReportMemoContainer />
      <ReportShowFooterContainer />
    </Container>
  );
};

export default ReportShowContainer;
