import React, { useState } from "react";
import { PostResponse } from "@doczip/api-client";
import styled from "styled-components";
import { Button } from "@/components/Mui";
import PostNewModal from "@/features/posts/components/PostNewModal";

const Container = styled.div``;

const ClickControlBox = styled.div`
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 120px;
    height: 46px;

    font-weight: 700;
    font-size: 14px;
  }
`;

type PostModalOpenButtonProps = {
  as?: React.ReactNode;
  post?: PostResponse | null;
};

const PostModalOpenButton = ({ as, post }: PostModalOpenButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Container>
      <ClickControlBox onClick={() => setModalOpen(true)}>
        {as ?? <StyledButton className="outlined">공지 작성하기</StyledButton>}
      </ClickControlBox>
      <PostNewModal open={modalOpen} handleClose={() => setModalOpen(false)} post={post} />
    </Container>
  );
};

export default PostModalOpenButton;
