import React from "react";
import styled from "styled-components";
import ReportReminderItem from "./ReportReminderItem";
import { ChangeNotification } from "api/dist";
import { NoResultChangeNotification } from "@/components/NoResult";

const ReminderListContainer = styled.div`
  background-color: white;
`;

// const ReminderListContainerInnerTitle = styled.h1`
//   margin: 0;
//   padding: 16px 20px 8px 20px;

//   font-weight: 700;
//   font-size: 12px;
// `;

type Props = {
  reports: ChangeNotification[];
};

const ReportReminderListByDate = ({ reports }: Props) => {
  return (
    <ReminderListContainer>
      {/* <ReminderListContainerInnerTitle>오늘</ReminderListContainerInnerTitle> */}
      {reports.length > 0 ? (
        reports.map((report) => <ReportReminderItem report={report} key={report.created_datetime} />)
      ) : (
        <NoResultChangeNotification
          title="아직 변동된 등기부등본이 없습니다."
          description="🔍 새로운 변동 사항이 생기면 알려드리겠습니다."
        />
      )}
    </ReminderListContainer>
  );
};

export default ReportReminderListByDate;
