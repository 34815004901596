import React, { useCallback, useEffect, useMemo } from "react";
import { UserType } from "@doczip/api-client";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Carousel from "@/components/Carousel";
import SearchTypeSelect, { ExtendSearchType } from "@/components/dropdown/SearchTypeSelect";
import NoResult from "@/components/NoResult";
import TitledContainer from "@/components/TitledContainer";
import IssuedReportListSearchField from "@/features/reports/components/issued/IssuedReportListSearchField";
import { ReportLogList } from "@/features/reports/components/ReportLog";
import {
  CheckCreatedDateContainer,
  SearchTypeContainer,
} from "@/features/user/components/logs/userIssuedReportListLayout";
import UserCommonLayout from "@/features/user/components/UserCommonLayout";
import {
  resetIssuedReportLogListState,
  resetSearchTypeState,
  setExtraSearchType,
  setSearchType,
} from "@/redux/logs/actions";
import { getIssuedReportLogListState, getSearchType } from "@/redux/logs/selectors";
import { fetchMoreIssuedReportLogListThunk } from "@/redux/logs/thunk";
import { ExtraSearchType, SearchType } from "@/redux/logs/types";
import { getUserMe } from "@/redux/user/selectors";
import { lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const AddressSearchContainer = styled.form`
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-right: 36px;
  padding-bottom: 30px;
  padding-left: 36px;

  background-color: #ff8f1716;

  @media screen and (max-width: ${lengths.tablet}) {
    margin-bottom: 0;
    padding: 0;

    background-color: transparent;
  }
`;

interface IssuedReportListPageProps {}

export default function IssuedReportListPage(props: IssuedReportListPageProps) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paramQuery = searchParams.get("query");
  const paramSearchType = searchParams.get("searchType") as SearchType;
  const paramOwnerIdentityCode = searchParams.get("identityCode");

  const {
    value: issuedReportLogList,
    fetchState: issuedReportLogListFetchState,
    page: issuedReportLogListPage,
    totalPage: issuedReportLogListTotalPage,
  } = useSelector(getIssuedReportLogListState);

  const searchType = useSelector(getSearchType);
  const user = useSelector(getUserMe);

  const isOwnerSearch = useMemo(() => {
    if (!paramSearchType && !searchType) {
      return;
    }

    return (searchType ?? paramSearchType) === "owner";
  }, [paramSearchType, searchType]);

  const handleLoadMoreIssuedReportLogList = useCallback(
    (query = paramQuery, identityCode = paramOwnerIdentityCode) => {
      if (issuedReportLogListFetchState === "FETCHING" || !(query || paramQuery) || !(searchType || paramSearchType)) {
        dispatch(fetchMoreIssuedReportLogListThunk({}));
        return;
      }

      let registeredAddress: string | undefined;
      let ownerName: string | undefined;
      let ownershipTargetStakeholder: string | undefined;

      switch (paramSearchType ?? searchType) {
        case "address":
          registeredAddress = query;
          break;
        case "owner":
          ownerName = query;
          break;
        case "interest":
          ownershipTargetStakeholder = query;
          break;
      }

      dispatch(
        fetchMoreIssuedReportLogListThunk({
          registeredAddress,
          ownerName,
          ownerIdentityCode: identityCode || void 0,
          ownershipTargetStakeholder,
        }),
      );
    },
    [issuedReportLogListFetchState, paramQuery, searchType, paramOwnerIdentityCode],
  );

  const subscription = useMemo(() => {
    if (!user || !user?.subscription) {
      return;
    }

    return user.subscription;
  }, [user]);

  const admin = useMemo(() => {
    if (!user || !user?.user_type) {
      return;
    }

    return user.user_type === UserType.Admin;
  }, [user]);

  const handleSearchTypeChange = (searchType: ExtendSearchType) => {
    dispatch(setSearchType(searchType as SearchType));
  };

  const handleExtraSearchTypeChange = (searchType: ExtendSearchType) => {
    dispatch(setExtraSearchType(searchType as ExtraSearchType));
  };

  useEffect(() => {
    if (!["address", "owner", "interest"].includes(searchType)) {
      dispatch(setSearchType("address"));

      dispatch(resetIssuedReportLogListState());
      dispatch(resetSearchTypeState());
    }
  }, []);

  useEffect(() => {
    if (searchType === "owner") {
      dispatch(setExtraSearchType("individual"));
    }
  }, [searchType]);

  if (!admin && !subscription) {
    return <Navigate to={getUrl("ownersClub")} />;
  }

  return (
    <UserCommonLayout>
      <Carousel
        slideList={[
          {
            descriptions: ["오너스클럽 회원은 무료로 닥집 보유 등기부등본 열람이 가능합니다."],
            href: getUrl("postsRoot"),
          },
          {
            descriptions: ["오너스클럽 회원은 신규 열람을 위한 무료 다운로드권 5개를 매달 제공합니다."],
            href: getUrl("postsRoot"),
          },
        ]}
      />
      <AddressSearchContainer>
        <TitledContainer
          title="검색할 등기"
          actions={<CheckCreatedDateContainer>발급일자를 꼭 확인해주세요!</CheckCreatedDateContainer>}
        />
        <SearchTypeContainer>
          <SearchTypeSelect
            items={[
              { key: "address", name: "주소" },
              { key: "owner", name: "소유주" },
              { key: "interest", name: "이해관계인" },
            ]}
            defaultValue={["address", "owner", "interest"].includes(searchType) ? searchType : "address"}
            onTypeChange={handleSearchTypeChange}
            withText={!isOwnerSearch}
          />
          {isOwnerSearch && (
            <SearchTypeSelect
              items={[
                { key: "individual", name: "개인" },
                { key: "corporate", name: "법인" },
              ]}
              defaultValue="individual"
              onTypeChange={handleExtraSearchTypeChange}
              withText
            />
          )}
        </SearchTypeContainer>
        <IssuedReportListSearchField />
      </AddressSearchContainer>
      <TitledContainer title={paramQuery ? "닥집 보유 등기부 검색 내역" : "최신 닥집 등기부 목록"} />
      <ReportLogList
        list={issuedReportLogList}
        fetchState={issuedReportLogListFetchState === "READY" ? "SUCCESS" : issuedReportLogListFetchState}
        page={issuedReportLogListPage}
        totalPage={issuedReportLogListTotalPage}
        onLoadMore={handleLoadMoreIssuedReportLogList}
        query={paramQuery ?? void 0}
        reportLogProps={{ isIssued: true }}
        noResult={
          paramQuery ? (
            void 0
          ) : (
            <NoResult
              description={[
                "📂아직 열람 내역이 없습니다.",
                "주소, 아파트명, 지역명 등 관심 키워드로 검색해 닥집이 보유한 등기부등본을 무한 검색해보세요!",
              ]}
            />
          )
        }
      />
    </UserCommonLayout>
  );
}
