import React from "react";
import { useSelector } from "react-redux";
import DashboardCarousel from "@/features/dashboard/components/DashboardCarousel";
import { getLimitedPostList, getLimitedPostListFetchState } from "@/redux/posts/selectors";

interface DashboardCarouselContainerProps {}

const DashboardCarouselContainer: React.FC<DashboardCarouselContainerProps> = () => {
  const posts = useSelector(getLimitedPostList);
  const postsFetchState = useSelector(getLimitedPostListFetchState);

  return <DashboardCarousel posts={posts} postsFetchState={postsFetchState} />;
};

export default DashboardCarouselContainer;
