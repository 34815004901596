import React from "react";
import SaleLayout from "@/features/sales/components/common/SaleLayout";
import SaleShowContainer from "@/features/sales/containers/SaleShowContainer";

const SaleShowPage = () => {
  return (
    <SaleLayout saleShow>
      <SaleShowContainer />
    </SaleLayout>
  );
};

export default SaleShowPage;
