import React from "react";
import classnames from "classnames";
import { decamelizeKeys } from "humps";
import styled from "styled-components";
import Layout from "@/components/Layout";
import { colors, lengths } from "@/styles/values";

export const OuterContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - ${lengths.headerHeight} - ${lengths.footerHeight});
  padding: 30px 48px;

  @media screen and (max-width: ${lengths.desktop}) {
    padding-top: 12px;
    padding-right: 0;
    padding-bottom: 24px;
    padding-left: 0;
  }

  &.sale-show {
    background-color: ${colors.white};
    border-left: 1px solid #dfe2e7;
  }
`;

export const InnerContainer = styled.div`
  max-width: 1234px;
  margin: 0 auto;

  @media screen and (max-width: ${lengths.fullDesktop}) {
    max-width: 816px;
  }

  @media screen and (max-width: 864px) {
    max-width: 100%;
  }
`;

export const HeaderContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type SaleLayoutProps = {
  children: React.ReactNode;
  saleShow?: boolean;
};

const SaleLayout = ({ children, saleShow }: SaleLayoutProps) => {
  return (
    <OuterContainer className={classnames(decamelizeKeys({ saleShow }, { separator: "-" }))}>
      {saleShow && <Layout isBorderLeftInFooter />}
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  );
};

export default SaleLayout;
