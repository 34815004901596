import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import { colors, depths } from "@/styles/values";

const DialogShade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${depths.overlayShade};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background-color: ${colors.black}aa;
`;

const DialogBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: calc(100% - 16px);
  max-width: 640px;
  height: 240px;
  max-height: 90vh;
  max-height: calc(var(--vh, 1vh) * 90);

  background-color: ${colors.white};
`;

const ProgressBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 9px;
  width: 480px;
  max-width: 90%;

  color: ${colors.main};

  & .MuiLinearProgress-root {
    height: 9px;
  }
`;

const HelperText = styled.div`
  color: ${colors.dark};
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
`;

type Props = {};

function sigmoid(z: number) {
  return 1 / (1 + Math.exp(-z));
}

const ReportLoadingDialog: React.FC<Props> = (props) => {
  const [elapsed, setElapsed] = useState(0);
  const [startAt, setStartAt] = useState(Date.now());

  useEffect(() => {
    setStartAt(Date.now());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed(Date.now() - startAt);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [startAt]);

  // Simulate the progress bar based on sigmoid function.
  // Shows an indeterminate progress bar after 3 minutes.

  const progress = Math.min(elapsed / 180000, 1);
  const value = (sigmoid(progress * 5) - 0.5) * 200;
  const variant = progress >= 1 ? "indeterminate" : "determinate";

  return value <= 3 ? (
    <LoadingCircle />
  ) : (
    <DialogShade>
      <DialogBox>
        <ProgressBox>
          <LinearProgress variant={variant} value={value} color="inherit" />
          <HelperText>공부 문서 열람 중... {progress >= 1 ? 99 : value.toFixed(0)}%</HelperText>
        </ProgressBox>
      </DialogBox>
    </DialogShade>
  );
};

export default ReportLoadingDialog;
