import React from "react";
import { useSelector } from "react-redux";
import ReportMemo from "@/features/reports/components/ReportMemo";
import { getReport, getReportFetchState } from "@/redux/report/selectors";

interface ReportMemoContainerProps {}

const ReportMemoContainer: React.FC<ReportMemoContainerProps> = () => {
  const report = useSelector(getReport);
  const reportFetchState = useSelector(getReportFetchState);

  return <ReportMemo report={report} reportFetchState={reportFetchState} />;
};

export default ReportMemoContainer;
