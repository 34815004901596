import React, { useState } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { addMonths, format } from "date-fns";
import styled from "styled-components";
import Dialog from "@/components/Dialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type Props = {
  open: boolean;
  onAccept: (count: number) => void;
  onClose: () => void;
};

interface Option {
  value: number;
  label: string;
}

const PERIOD_OPTION = [1, 2, 3, 6, 12, 24];

const ReportReminderModal = ({ open, onAccept, onClose }: Props) => {
  const [selectedValue, setSelectedValue] = useState<number>(PERIOD_OPTION[0]);

  const options: Option[] = PERIOD_OPTION.map((num) => {
    const label = num < 12 ? `${num}개월` : `${Math.floor(num / 12)}년`;
    return { value: num, label };
  });

  const handleChange = (event: SelectChangeEvent<number>) => {
    setSelectedValue(parseInt(event.target.value as string, 10));
  };

  const today = new Date();
  const expiryDate = addMonths(today, selectedValue);
  const formattedExpiryDate = format(expiryDate, "yyyy년 M월 d일");

  const content = (
    <Container>
      <SelectContainer>
        <div>기간설정 (개월당 이용권 1건 차감)</div>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            onChange={handleChange}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectContainer>

      <SelectContainer>
        <div>차감 이용권 수: {selectedValue}건</div>
        <div>예상 만기일: {formattedExpiryDate}</div>
      </SelectContainer>
    </Container>
  );

  const handleConfirmAccept = () => {
    if (onAccept) {
      onAccept(selectedValue);
    }
  };

  return (
    <>
      {open && (
        <Dialog
          title="다운로드권이 사용됩니다."
          content={content}
          onConfirm={handleConfirmAccept}
          onClose={onClose}
          confirmText="진행하기"
          closeText="닫기"
        />
      )}
    </>
  );
};

export default ReportReminderModal;
