import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ChangeNotification } from "api/dist";
import { reportApi } from "@/client";
import MobileInnerCommonLayout from "@/components/MobileInnerCommonLayout";
import TitledContainer from "@/components/TitledContainer";
import ReportReminderListByDate from "@/features/reports/components/reminder/ReportReminderListByDate";
import { getUrl } from "@/utils/routes";

const ReminderListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ReportReminderListContainer = () => {
  const [, setError] = useState(false);
  const [notis, setNotis] = useState<ChangeNotification[]>([]);

  const fetchChangeNotification = async () => {
    try {
      const response = await reportApi.getChangeNotifications();
      const data = response.data;
      setNotis(data);
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchChangeNotification();
  }, []);

  return (
    <>
      <MobileInnerCommonLayout>
        <TitledContainer
          title="변동 알림 내역"
          actions={
            <Link to={getUrl("reportsReminderSetting")}>
              <div style={{ fontWeight: 700, fontSize: 12, color: "#545454" }}>변동알림 설정</div>
            </Link>
          }
        />
        <ReminderListsContainer>
          <ReportReminderListByDate reports={notis} />
        </ReminderListsContainer>
      </MobileInnerCommonLayout>
    </>
  );
};

export default ReportReminderListContainer;
