import React from "react";
import { Report, ReportBuildingLedger, ReportLandUsingInfo, ReportRegisterLedger } from "@blitz-core/api-client";
import { ReportLogResponse } from "@doczip/api-client";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ReportItem from "./ReportItem";
import InfiniteScrollList from "@/components/InfiniteScrollList";
import TitledWrapper from "@/components/TitledWrapper";
import ReportListNoResultPlaceholder from "@/features/reports/components/ReportListNoResultPlaceholder";
import { FetchState } from "@/interfaces/fetch";
import { fetchMoreReportLogListThunk } from "@/redux/user/thunk";

const Container = styled.div`
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`;

interface ReportListProps {
  reportLogList: ReportLogResponse[] | null;
  reportLogListFetchState: FetchState;
  reportLogListPage: number | null;
  reportLogListTotalPage: number | null;
  reportLogDocument?: Report | null;
  reportLogDocumentFetchState?: FetchState;
  registerLedger: ReportRegisterLedger | null;
  registerLedgerFetchState: FetchState;
  buildingLedger: ReportBuildingLedger | null;
  buildingLedgerFetchState: FetchState;
  landUsingInfo: ReportLandUsingInfo | null;
  landUsingInfoFetchState: FetchState;
}

const ReportList: React.FC<ReportListProps> = ({
  reportLogList,
  reportLogListFetchState,
  reportLogListPage,
  reportLogListTotalPage,
  reportLogDocument,
  registerLedger,
  registerLedgerFetchState,
  buildingLedger,
  buildingLedgerFetchState,
  landUsingInfo,
  landUsingInfoFetchState,
}) => {
  const dispatch = useDispatch();

  const onLoadMore = () => {
    dispatch(fetchMoreReportLogListThunk({ deleted: false }));
  };

  return (
    <Container>
      <TitledWrapper title="최근 열람 내역" titleStyle={{ size: 14, weight: 700, bottomGap: 16 }}>
        <InfiniteScrollList
          list={reportLogList}
          fetchState={reportLogListFetchState}
          page={reportLogListPage}
          totalPage={reportLogListTotalPage}
          onLoadMore={onLoadMore}
          noResultPlaceHolder={<ReportListNoResultPlaceholder />}
          renderItem={(reportLog, index) => (
            <ReportItem
              key={index}
              reportLog={reportLog}
              reportLogDocument={reportLogDocument}
              registerLedger={registerLedger}
              registerLedgerFetchState={registerLedgerFetchState}
              buildingLedger={buildingLedger}
              buildingLedgerFetchState={buildingLedgerFetchState}
              landUsingInfo={landUsingInfo}
              landUsingInfoFetchState={landUsingInfoFetchState}
            />
          )}
        />
      </TitledWrapper>
    </Container>
  );
};

export default ReportList;
